// src/context/SearchContext.js

import React, { createContext, useContext, useState } from "react";
import { liteClient as algoliasearch } from "algoliasearch/lite"; // Correct named import for Algolia version 5.x
import { collection, query, where, getDocs, limit, addDoc } from "firebase/firestore";
import { useFirestore } from "../firebase/FirestoreContext";
import { getAuth } from "firebase/auth";

// Initialize Algolia client using named import
const searchClient = algoliasearch("12LBFO6UW5", "f4738b59976d073ecf6e2d4ccdb07c8c");
const algoliaIndex = searchClient;

const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
  const db = useFirestore();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchHistory, setSearchHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showRecentSearches, setShowRecentSearches] = useState(false); // New state for managing recent searches

  //
  const auth = getAuth();
  const user = auth.currentUser;

  const getHistoryTerms = async () => {
    let searchHistory = [];
    let userId = user.uid; // Replace with the actual user ID
    if (userId) {
      const searchHistoryRef = collection(db, `Users/UsersList/DataBase/${userId}/SearchHistory`);
      const searchHistorySnapshot = await getDocs(searchHistoryRef);
      searchHistorySnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        searchHistory.push(data);
      });
      //alert(JSON.stringify(searchHistorySnapshot?.searchHistory));
      setSearchHistory(searchHistory);
    }
  };

  const storeSearchTerm = async (term) => {
    let userId = user.uid; // Replace with the actual user ID
    if (userId) {
      const searchHistoryRef = collection(db, `Users/UsersList/DataBase/${userId}/SearchHistory`);

      const querySnapshot = await getDocs(query(searchHistoryRef, where("searchTerm", "==", term), limit(1)));

      if (querySnapshot.empty) {
        await addDoc(searchHistoryRef, {
          searchTerm: term,
          dateCreated: new Date(),
        });
      }

      await getHistoryTerms();
    }
  };

  const searchProducts = async (term) => {
    console.log("Search initiated with term:", term); // Debugging log
    setLoading(true);
    setSearchResults([]); // Clear previous results

    try {
      const { hits } = await algoliaIndex.search(term);
      console.log("Search results:", hits); // Debugging log
      setSearchResults(hits);
    } catch (error) {
      console.error("Error searching products:", error);
      setSearchResults([]);
    } finally {
      setLoading(false);
    }

    console.log("Search completed."); // Debugging log
  };

  // Function to toggle the display of recent searches
  const toggleRecentSearches = (show) => {
    setShowRecentSearches(show);
  };

  return (
    <SearchContext.Provider
      value={{
        searchTerm,
        setSearchTerm,
        searchResults,
        searchHistory,
        loading,
        storeSearchTerm,
        searchProducts,
        getHistoryTerms,
        toggleRecentSearches, // Include toggleRecentSearches in the context provider
        showRecentSearches, // Include the state for showing recent searches
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = () => useContext(SearchContext);
