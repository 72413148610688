import React, { useState, useEffect } from "react";
import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, onAuthStateChanged, OAuthProvider } from "firebase/auth";

import { useNavigate } from "react-router-dom";
import "./SignIn.css"; // Import a CSS file for styling

const SignIn = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [user, setUser] = useState(null); // State to hold user info

  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user); // Update the state with the signed-in user's info
        setError(""); // Clear any error messages
        // You can also navigate to a different page here if desired
      } else {
        setUser(null); // Reset user info if not logged in
      }
    });

    return () => unsubscribe(); // Clean up the subscription
  }, [auth]);

  const handleSignIn = (event) => {
    event.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        setUser(userCredential.user); // Update state with the signed-in user
        setError(""); // Clear any previous error messages
        // navigate to "/" or another page if desired
        navigate(`/`);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const handleGoogleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        setUser(result.user);
        setError("");
        // navigate to "/" or another page if desired

        navigate(`/`);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const handleAppleSignIn = () => {
    const auth = getAuth();
    const provider = new OAuthProvider("apple.com");

    signInWithPopup(auth, provider)
      .then(async (result) => {
        const user = result.user;
        setUser(user); // Update state with the signed-in user
        setError(""); // Clear any error messages

        // Call the function to create or update the user in Firestore
        // await createUserInFirestore(user);

        navigate(`/`);
      })
      .catch((error) => {
        setError(error.message); // Display error message
      });
  };

  return (
    <div className="signin-container">
      <img src="/images/sooperstock_logo.svg" alt="Sooperstock" style={{ height: "47px", marginBottom: 10 }} />
      <p style={{ marginBottom: 20, fontSize: 12, textTransform: "uppercase", marginBottom: 50 }}>The plant wellness superstore</p>

      <form className="signin-form" onSubmit={handleSignIn}>
        <label>Sign in with email</label>
        <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
        <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
        <button type="submit" className="btn btn-primary">
          Log in
        </button>
      </form>

      <button className="btn btn-secondary" onClick={handleGoogleSignIn}>
        <img src="images/google_icon.png" style={{ height: "12px", top: "0px", marginRight: "3px", position: "relative" }} /> Continue With Google
      </button>

      <button className="btn btn-primary btn-apple" onClick={handleAppleSignIn}>
        <span role="img" aria-label="apple">
          
        </span>{" "}
        Sign in with Apple
      </button>

      {error && <p className="error">{error}</p>}

      <p className="footer-text">
        Forgot your password? <a href="/reset">Click here</a> or <a href="/signup">Sign Up</a>
      </p>

      <p className="footer-text">
        By creating an account, you agree to Sooperstock's <a href="/terms">terms of service</a>.
      </p>
      <p className="footer-text" onClick={() => navigate(`/`)} style={{ cursor: "pointer" }}>
        Go to <u>HomePage.</u>
      </p>
    </div>
  );
};

export default SignIn;
