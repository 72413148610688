import React, { useState, useEffect } from "react";
import Header from "../../elements/Header";

const FAQ = ({ isDevMode }) => {
  return (
    <div>
      <Header title="FAQ" />
      <div style={{ padding: "20px" }}>
        <div>
          FAQ
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default FAQ;
