import React, { useState, useEffect, useContext } from "react";
import { auth, db } from "../../firebase/firebaseConfig";
import { onSnapshot, collection, query, where } from "firebase/firestore";
import Header from "../../elements/Header";
import { UserContext } from "../../context/UserContext";
import moment from "moment";

export const OrderHistory = ({}) => {
  const { user } = useContext(UserContext);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedOrder, setExpandedOrder] = useState(null); // Tracks expanded order for details

  useEffect(() => {
    if (user) {
      const q = query(collection(db, "Orders/OrdersList/DataBase"), where("user_id", "==", user.uid));
      const unsubscribe = onSnapshot(
        q,
        (snapshot) => {
          const fetchedOrders = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          setOrders(fetchedOrders);
          setLoading(false);
        },
        (error) => {
          console.error("Error fetching orders: ", error);
          setLoading(false);
        }
      );

      // Cleanup subscription on unmount
      return () => unsubscribe();
    }
  }, []);

  const toggleOrderDetails = (orderId) => {
    setExpandedOrder(expandedOrder === orderId ? null : orderId);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div style={styles.container}>
      <Header />
      <h2 style={styles.h2}>Current order(s)</h2>
      <div style={styles.content}>
        {orders.map((order) => (
          <div key={order.id} style={styles.orderCard}>
            <img src={order.items[0].images[0]} alt={order.items[0].product_name} style={styles.productImage} />
            <div style={styles.orderDetails}>
              <div style={styles.orderHeader}>
                <h3 style={styles.productName}>{order.items[0].product_name}</h3>
                <span style={styles.orderLink} onClick={() => toggleOrderDetails(order.id)}>
                  {order?.items?.length > 1 ? (expandedOrder === order.id ? "hide details" : "show details") : ""}
                </span>
              </div>
              <p style={styles.orderInfo}>
                Placed on: {moment(order?.paymentDetails?.payment?.createdAt).format("MM/DD/YY, h:mm a")} | {order.status}
              </p>
              <p style={styles.orderInfo}>
                Qty: {order.items.reduce((total, item) => total + item.quantity, 0)}{" "}
                <span style={styles.price}>${order.items.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2)}</span>
              </p>

              {expandedOrder === order.id && (
                <div style={styles.orderItems}>
                  {order?.items?.length > 1 &&
                    order.items.map((item, index) => (
                      <div key={item.id} style={styles.itemDetails}>
                        <div>
                          <p style={{ fontSize: 13 }}>
                            <span style={{ fontWeight: "500" }}>
                              {index + 1}. {item.product_name}
                            </span>{" "}
                            ({item.brand_name}) | Qty: {item.quantity}, <span style={{ color: "green", fontWeight: "600" }}>${item.price}</span>
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  container: {},
  content: {
    padding: "20px",
  },
  h2: {
    fontSize: "18px",
    fontWeight: "bold",
    color: "#333",
    padding: "0px 20px",
    marginTop: "20px",
  },
  orderCard: {
    display: "flex",
    alignItems: "flex-start",
    border: "1px solid #ccc",
    padding: "15px",
    marginBottom: "10px",
    borderRadius: "4px",
    backgroundColor: "#fff",
  },
  productImage: {
    width: "50px",
    height: "50px",
    marginRight: "15px",
    //
    objectCover: "contain",
  },
  orderDetails: {
    flex: 1,
  },
  orderHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  productName: {
    fontSize: "16px",
    fontWeight: "bold",
    margin: 0,
  },
  orderLink: {
    fontSize: "14px",
    color: "#007BFF",
    cursor: "pointer",
    textDecoration: "underline",
  },
  orderInfo: {
    margin: "5px 0",
    color: "#666",
    fontSize: "14px",
  },
  price: {
    color: "green",
    fontWeight: "bold",
    marginLeft: "10px",
  },
  orderItems: {
    marginTop: "10px",
  },
  itemDetails: {
    display: "flex",
    marginBottom: "10px",
  },
  itemImage: {
    width: "40px",
    height: "60px",
    marginRight: "10px",
  },
};

export default OrderHistory;
