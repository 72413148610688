import React, { useState, useEffect, useRef } from "react";
import Header from "../../elements/Header";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { collection, getDocs, query, where, doc, getDoc } from "firebase/firestore";
import { useFirestore } from "../../firebase/FirestoreContext";
import isListingOnSale from "../../functions/isListingOnSale";
import handleQuantityChange from "../../functions/handleQuantityChange"; // Import the function
import handleDeleteItem from "../../functions/handleDeleteItem"; // Import the function
import { currentProductPrice } from "../../functions/currentProductPrice";

const Cart = () => {
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [cart_sooperstock_ID, setCart_sooperstock_ID] = useState(null);
  const [shippingCost, setShipingCost] = useState(0);
  const [freeShipingThreshold, setFreeShipingThreshold] = useState(0);
  const [disabledItems, setDisabledItems] = useState({});

  const firestore = useFirestore();
  const auth = getAuth();
  const user = auth.currentUser;
  let subtotal = 0;

  useEffect(() => {
    if (user) {
      fetchCartItems();
      fetchShipmentSettings();
    }
  }, [user]);

  // get shipmentPrice and freeShipmentThreshold
  const fetchShipmentSettings = async () => {
    try {
      //
      const shipmentDocRef = doc(firestore, "SooperstockSettings", "Shipment");
      const shipmentDocSnapshot = await getDoc(shipmentDocRef);

      if (shipmentDocSnapshot.exists()) {
        //
        const shipmentData = shipmentDocSnapshot.data();
        shipmentData?.shipmentPrice && setShipingCost(shipmentData?.shipmentPrice);
        shipmentData?.freeShipmentThreshold && setFreeShipingThreshold(shipmentData?.freeShipmentThreshold);
      } else {
        console.error("No such document!");
      }
    } catch (error) {
      console.error("Error fetching document:", error);
    }
  };

  const fetchUserCart = async (firestore) => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      return null;
    }

    const cartQuery = query(collection(firestore, `Carts/CartsList/DataBase`), where("user_id", "==", user.uid), where("status", "==", "ordering"));

    const cartQuerySnapshot = await getDocs(cartQuery);

    if (!cartQuerySnapshot.empty) {
      setCart_sooperstock_ID(cartQuerySnapshot.docs[0].data()?.cart_sooperstock_ID + 123);
      return cartQuerySnapshot.docs[0]; // Return the first cart document
    }

    return null;
  };

  const fetchCartItems = async () => {
    try {
      const cartDoc = await fetchUserCart(firestore);

      if (cartDoc) {
        const itemsRef = collection(firestore, `Carts/CartsList/DataBase/${cartDoc.id}/Items`);
        const itemsSnapshot = await getDocs(itemsRef);

        const items = await Promise.all(
          itemsSnapshot.docs.map(async (doc) => {
            const itemData = doc.data();
            const priceInfo = await currentProductPrice(itemData); // Calculate finalPrice

            return {
              id: doc.id,
              cartID: cartDoc.id,
              ...itemData,
              finalPrice: priceInfo.finalPrice, // Use finalPrice from currentProductPrice
            };
          })
        );

        // sorting by seller_id
        const sortedItems = items.sort((a, b) => (a.seller_id > b.seller_id ? 1 : -1));

        setCart_sooperstock_ID(cartDoc.data()?.cart_sooperstock_ID);
        setCartItems(sortedItems);
      } else {
        // Handle case where the cart is not found
        setCartItems([]);
      }
    } catch (error) {
      console.error("Error fetching cart items:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCheckout = async () => {
    const unavailableItems = [];

    // Check availability of each item in the cart
    for (let item of cartItems) {
      const itemDocRef = doc(firestore, "Products", item.seller_product_id); // Adjust path if needed
      const itemDocSnapshot = await getDoc(itemDocRef);

      if (itemDocSnapshot.exists()) {
        const itemData = itemDocSnapshot.data();

        // Check if product is out of stock or inactive
        if (itemData.listing_stock_amount <= 0 || itemData.status === "inactive") {
          unavailableItems.push(item);
        }
      }
    }

    // If there are unavailable items, notify the user and remove those items from the cart
    if (unavailableItems.length > 0) {
      alert("One or more items in your bag are no longer available. Please refresh the page for current availability.");

      // Remove unavailable items from the cart
      const updatedCartItems = cartItems.filter((item) => !unavailableItems.includes(item));
      setCartItems(updatedCartItems); // Update cart items state

      // Optionally, refresh the cart by refetching items from Firestore to ensure data consistency
      await fetchCartItems(); // Refresh cart to reflect updated availability
    } else {
      // If all items are available, proceed to checkout
      navigate("/checkout", {
        state: {
          cartID: cartItems.length ? cartItems[0].cartID : null,
          items: cartItems,
          cart_sooperstock_ID: cart_sooperstock_ID,
          subtotal,
          shippingCostsubtotal,
        },
      });
    }
  };

  const handleQuantityUpdate = async (item, quantityChange) => {
    setDisabledItems((prev) => ({ ...prev, [item.id]: true })); // Disable buttons for this item

    try {
      await handleQuantityChange(firestore, item.cartID, item.seller_product_id, quantityChange, setCartItems);
    } catch (error) {
      console.error("Error updating quantity: ", error);
    } finally {
      setDisabledItems((prev) => ({ ...prev, [item.id]: false })); // Re-enable buttons for this item
    }
  };

  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  // Map through cart items and accumulate subtotal
  cartItems.forEach((item) => {
    const itemTotal = (item.finalPrice || 0) * (item.quantity || 1);
    subtotal += itemTotal; // Accumulate subtotal here
  });

  // Calculate shipping cost based on the final subtotal
  const shippingCostsubtotal = subtotal < freeShipingThreshold && subtotal > 0 ? shippingCost : 0;

  //
  let currentSellerId = null;
  let packageCount = 0;

  return (
    <div>
      <Header />
      <div style={styles.cartContainer}>
        <div style={{ padding: "20px 0px 0px 10px", color: "green", fontWeight: "600" }}>
          {shippingCostsubtotal !== 0 && "FREE shipping for orders over $100"}
        </div>

        <h3 style={styles.cartTitle}>
          Total{" "}
          <b>
            {cartItems.length} {cartItems.length === 1 ? "item" : "items"}
          </b>
        </h3>

        {cartItems.map((item, index) => {
          let displayPackageInfo = false;

          // Check if the seller_id changes and display package info
          if (item.seller_id !== currentSellerId) {
            currentSellerId = item.seller_id;
            packageCount++;
            displayPackageInfo = true;
          }

          return (
            <div key={item.id} style={{ padding: "0px 10px" }}>
              {displayPackageInfo && (
                <div style={{ fontSize: "12px", color: "gray", marginTop: 10, marginBottom: 6 }}>
                  Package {packageCount} of {cartItems.filter((i, idx) => idx === 0 || cartItems[idx - 1].seller_id !== i.seller_id).length} (
                  {cartItems.filter((i) => i.seller_id === item.seller_id).length}{" "}
                  {cartItems.filter((i) => i.seller_id === item.seller_id).length > 1 ? "products" : "product"})
                </div>
              )}
              <div style={styles.cartItem}>
                <img src={(item?.images && item?.images[0]) || "https://placehold.jp/100x100.png"} alt="Product" style={styles.productImage} />
                <div style={styles.productDetails}>
                  <h4 style={styles.productTitle}>{item.product_name}</h4>
                  <div style={styles.productInfo}>
                    <span>
                      ${item?.finalPrice || 0} | {item?.quantity && `Qty: ${item?.quantity} | Total: $${(item?.finalPrice * item?.quantity).toFixed(2)}`}
                    </span>
                  </div>
                  <button
                    style={{ ...styles.deleteButton, opacity: disabledItems[item.id] ? 0.5 : 1, cursor: disabledItems[item.id] ? "not-allowed" : "pointer" }}
                    onClick={async () => {
                      if (!disabledItems[item.id]) {
                        setDisabledItems((prev) => ({ ...prev, [item.id]: true })); // Disable the delete button for this item

                        try {
                          await handleDeleteItem(firestore, item.cartID, item.seller_product_id, item.quantity, setCartItems);
                          fetchCartItems(); // Refresh the cart after the item is deleted
                        } catch (error) {
                          console.error("Error deleting item: ", error);
                        } finally {
                          setDisabledItems((prev) => ({ ...prev, [item.id]: false })); // Re-enable the delete button after the operation
                        }
                      }
                    }}
                    disabled={disabledItems[item.id]} // Disable the button while processing
                  >
                    delete item
                  </button>
                </div>
                <div style={styles.quantityControls}>
                  <p
                    onClick={async () => {
                      if (!disabledItems[item.id]) {
                        setCartItems((prevItems) =>
                          prevItems.map((cartItem) => (cartItem.id === item.id ? { ...cartItem, quantity: Math.max(cartItem.quantity + 1, 0) } : cartItem))
                        );
                        await handleQuantityUpdate(item, 1); // Increase quantity
                      }
                    }}
                    style={{ ...styles.quantityButton, borderBottom: "1px solid #ddd", opacity: disabledItems[item.id] ? 0.5 : 1 }}
                  >
                    +
                  </p>
                  <p
                    onClick={async () => {
                      if (!disabledItems[item.id]) {
                        setCartItems((prevItems) =>
                          prevItems.map((cartItem) => (cartItem.id === item.id ? { ...cartItem, quantity: Math.max(cartItem.quantity - 1, 0) } : cartItem))
                        );
                        await handleQuantityUpdate(item, -1); // Decrease quantity
                      }
                    }}
                    style={{ ...styles.quantityButton, opacity: disabledItems[item.id] ? 0.5 : 1 }}
                  >
                    -
                  </p>
                </div>
              </div>
            </div>
          );
        })}

        <div style={styles.summary}>
          <h3 style={styles.summaryTitle}>Summary</h3>
          <div style={styles.summaryRow}>
            <span>Items</span>
            <span>${subtotal.toFixed(2)}</span> {/* Use accumulated subtotal */}
          </div>
          <div style={styles.summaryRow}>
            <span>Shipping</span>
            <span>{shippingCostsubtotal == 0 ? "FREE" : `$${shippingCostsubtotal.toFixed(2)}`}</span>
          </div>
          <div style={styles.subtotalRow}>
            <span>Subtotal:</span>
            <span style={styles.subtotal}>${(subtotal + shippingCostsubtotal).toFixed(2)}</span>
          </div>
          <button style={styles.checkoutButton} onClick={handleCheckout}>
            Proceed to checkout
          </button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  cartContainer: {
    backgroundColor: "#fff",
    margin: "0 auto",
  },
  cartTitle: {
    fontSize: "12px",
    color: "gray",
    padding: "0 10px",
    paddingTop: "20px",
    fontWeight: 100,
    paddingBottom: "10px",
  },
  cartItem: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ddd",
    marginBottom: "5px",
  },
  productImage: {
    width: "50px",
    height: "50px",
    margin: "0px 10px",
  },
  productDetails: {
    flex: 1,
  },
  productTitle: {
    fontSize: "14px",
    marginBottom: "5px",
    fontWeight: 200,
  },
  productInfo: {
    fontSize: "14px",
    color: "gray",
    marginBottom: "5px",
  },
  deleteButton: {
    fontSize: "12px",
    color: "blue",
    cursor: "pointer",
    background: "none",
    border: "none",
    padding: 0,
  },
  quantityControls: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderLeft: "1px solid #ddd",
    height: "80px",
  },
  quantityButton: {
    width: "40px",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    fontSize: "20px",
  },
  summary: {
    marginTop: "10px",
    padding: "10px",
    borderTop: "8px solid #ececeb",
  },
  summaryTitle: {
    fontSize: "14px",
    color: "gray",
    marginBottom: "10px",
  },
  summaryRow: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "5px",
    fontSize: "14px",
  },
  subtotalRow: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
    fontWeight: "bold",
  },
  subtotal: {
    color: "green",
  },
  checkoutButton: {
    width: "100%",
    backgroundColor: "black",
    color: "white",
    padding: "10px",
    marginTop: "20px",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
  },
};

export default Cart;
