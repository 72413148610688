import React, { useState, useEffect, useRef } from "react";
import "../styles/global.css"; // Ensure path is correct
import { ExpandMore, ExpandLess } from "@material-ui/icons";
import Cookies from "js-cookie";

const SearchFilters = ({ onApplyFilters }) => {
  const [openFilter, setOpenFilter] = useState(null); // Tracks which filter is open
  const [selectedCategories, setSelectedCategories] = useState([]); // Tracks selected categories
  const [selectedTypes, setSelectedTypes] = useState([]); // Tracks selected types
  const [isOnSale, setIsOnSale] = useState(false); // Tracks on sale toggle
  const [priceOrder, setPriceOrder] = useState(""); // Tracks price order filter
  const [inStockOnly, setInStockOnly] = useState(false); // Tracks in stock filter
  const [initialLoad, setInitialLoad] = useState(true); // Tracks whether the filters are being populated from cookies for the first time

  const filterRef = useRef(null);

  const categories = ["Flower", "Preroll", "Carts", "Pods", "Dispo", "Gummy", "Chocolate"];
  const types = ["Plant", "Flower"];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setOpenFilter(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [filterRef]);

  const applyFilters = () => {
    const filters = {
      categories: selectedCategories,
      types: selectedTypes,
      isOnSale,
      priceOrder,
      inStockOnly,
    };
    Cookies.set("filterPreferences", JSON.stringify(filters), { expires: 1 }); // Cookie expires in 7 days

    onApplyFilters(filters);
    setOpenFilter(null);
  };

  //
  useEffect(() => {
    if (initialLoad) {
      const savedFilters = Cookies.get("filterPreferences");
      if (savedFilters) {
        const { categories, types, isOnSale, priceOrder, inStockOnly } = JSON.parse(savedFilters);

        // Update state with the filters loaded from cookies
        setSelectedCategories(categories || []);
        setSelectedTypes(types || []);
        setIsOnSale(isOnSale || false);
        setPriceOrder(priceOrder || "");
        setInStockOnly(inStockOnly || false);

        // Apply the loaded filters
        onApplyFilters({
          categories: categories || [],
          types: types || [],
          isOnSale: isOnSale || false,
          priceOrder: priceOrder || "",
          inStockOnly: inStockOnly || false,
        });
      }
      setInitialLoad(false); // Mark initial load as complete
    }
  }, [initialLoad, onApplyFilters]);

  const toggleFilter = (filterName) => {
    if (openFilter !== null && openFilter !== filterName) {
      setOpenFilter(null);
      setTimeout(() => {
        setOpenFilter(filterName);
      }, 300);
    } else {
      setOpenFilter(openFilter === filterName ? null : filterName);
    }
  };

  const toggleCategory = (category) => {
    if (selectedCategories.includes(category)) {
      setSelectedCategories((prev) => prev.filter((item) => item !== category));
    } else {
      setSelectedCategories((prev) => [...prev, category]);
    }
  };

  const toggleOnSale = () => {
    setIsOnSale((prev) => {
      const updatedIsOnSale = !prev; // Toggle the value inside the setState
      const updatedFilters = {
        categories: selectedCategories,
        types: selectedTypes,
        isOnSale: updatedIsOnSale, // Pass the correct updated value for isOnSale
        priceOrder,
        inStockOnly,
      };

      // Apply filters with the updated isOnSale value
      onApplyFilters(updatedFilters);

      // Update cookies with the new isOnSale value
      Cookies.set(
        "filterPreferences",
        JSON.stringify({
          categories: selectedCategories,
          types: selectedTypes,
          isOnSale: updatedIsOnSale,
          priceOrder,
          inStockOnly,
        }),
        { expires: 1 }
      );

      return updatedIsOnSale; // Return the updated value to set state
    });
  };

  const togglePriceOrder = (order) => {
    setPriceOrder(order === priceOrder ? "" : order);
  };

  const toggleInStock = () => {
    setInStockOnly((prev) => !prev);
  };

  const clearFilters = () => {
    setSelectedCategories([]);
    setSelectedTypes([]);
    setOpenFilter(null);

    // Clear the filter preferences from cookies
    Cookies.remove("filterPreferences");

    onApplyFilters({
      categories: [],
      types: [],
      isOnSale: isOnSale,
      priceOrder: priceOrder,
      inStockOnly: inStockOnly,
    });
  };

  const clearOtherFilters = () => {
    setPriceOrder("");
    setInStockOnly(false);

    Cookies.remove("filterPreferences");
    // Apply filters with the updated state after clearing "Other" filters
    onApplyFilters({
      categories: selectedCategories, // Retain the selected categories
      types: selectedTypes, // Retain the selected types
      isOnSale: isOnSale, // Reset the "on sale" filter
      priceOrder: "", // Reset the "price order" filter
      inStockOnly: false, // Reset the "in stock only" filter
    });
    setOpenFilter(null);
  };

  return (
    <>
      {openFilter && <div style={styles.overlay} onClick={() => setOpenFilter(null)} />}

      <div ref={filterRef} style={styles.filterContainer}>
        <div style={styles.filterContainer}>
          {/* Filter Buttons */}
          <div style={styles.buttonsContainer}>
            <div
              onClick={() => toggleFilter("categories-types")}
              style={{
                ...styles.filterButton,
                borderRight: "1px solid #ddd",
                ...(selectedCategories.length > 0 || selectedTypes.length > 0 ? styles.selectedCategory : {}),
              }}
            >
              Categories {openFilter === "categories-types" ? <ExpandLess /> : <ExpandMore />}
            </div>
            <div
              onClick={() => toggleFilter("other-filters")}
              style={{
                ...styles.filterButton,
                borderRight: "1px solid #ddd",
                ...(priceOrder || inStockOnly ? styles.selectedCategory : {}),
              }}
            >
              More {openFilter === "other-filters" ? <ExpandLess /> : <ExpandMore />}
            </div>
            <div
              onClick={toggleOnSale} // Toggle the Deals filter and apply filters
              style={{
                ...styles.filterButton,
                ...(isOnSale ? styles.selectedCategory : {}),
              }}
            >
              Deals
            </div>
          </div>

          {/* Sliding Filter Panels */}
          {/* Categories Filter Panel */}
          <div
            style={{
              ...styles.filterPanel,
              maxHeight: openFilter === "categories-types" ? "200px" : "0",
            }}
          >
            <div>
              <div style={{ ...styles.categoriesContainer, ...styles.filterContent }}>
                {categories.map((category) => (
                  <button
                    key={category}
                    onClick={() => toggleCategory(category)}
                    style={{
                      ...styles.categoryButton,
                      ...(selectedCategories.includes(category) ? styles.selectedCategory : {}),
                    }}
                  >
                    {category}
                    {selectedCategories.includes(category) && <span style={styles.closeIcon}> &times;</span>}
                  </button>
                ))}
              </div>

              {/* Action buttons */}
              <div style={styles.actionButtonsContainer}>
                <button style={{ ...styles.actionButton, borderRight: "1px solid #ddd" }} onClick={clearFilters}>
                  Clear filters
                </button>
                <button style={styles.actionButton} onClick={applyFilters}>
                  Apply filters
                </button>
              </div>
            </div>
          </div>

          {/* Other Filters Panel */}
          <div
            style={{
              ...styles.filterPanel,
              maxHeight: openFilter === "other-filters" ? "250px" : "0",
            }}
          >
            <div style={styles.filterContent}>
              <div style={{ fontSize: ".8rem", marginBottom: "10px" }}>Sort By Price</div>
              <button
                onClick={() => togglePriceOrder("low-to-high")}
                style={{
                  ...styles.categoryButton,
                  ...(priceOrder === "low-to-high" ? styles.selectedCategory : {}),
                  marginRight: "15px",
                }}
              >
                Price: <b>Low to High</b>
              </button>
              <button
                onClick={() => togglePriceOrder("high-to-low")}
                style={{
                  ...styles.categoryButton,
                  ...(priceOrder === "high-to-low" ? styles.selectedCategory : {}),
                }}
              >
                Price: <b>High to Low</b>
              </button>
              <div style={{ fontSize: ".8rem", marginBottom: "10px", marginTop: "20px" }}>In Stock Only</div>
              <button
                onClick={toggleInStock}
                style={{
                  ...styles.categoryButton,
                  ...(inStockOnly ? styles.selectedCategory : {}),
                }}
              >
                In Stock Only
              </button>
            </div>
            {/* Action buttons */}
            <div style={styles.actionButtonsContainer}>
              <button style={{ ...styles.actionButton, borderRight: "1px solid #ddd" }} onClick={clearOtherFilters}>
                Clear filters
              </button>
              <button style={styles.actionButton} onClick={applyFilters}>
                Apply filters
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// Styles for SearchFilters component (unchanged)
const styles = {
  filterContainer: {
    position: "relative",
    backgroundColor: "#f4f4f4",
    zIndex: 1000,
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-around",
    borderBottom: "1px solid #ddd",
    backgroundColor: "white",
  },
  filterButton: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    border: "none",
    height: "45px",
    cursor: "pointer",
    fontSize: "14px",
    borderTop: ".2px solid #808080",
  },
  filterPanel: {
    position: "absolute",
    top: "45px",
    left: 0,
    right: 0,
    overflow: "hidden",
    transition: "max-height 0.3s ease",
    zIndex: 1,
    backgroundColor: "#fff",
    boxShadow: "0px 14px 15px rgba(0, 0, 0, 0.1)",
    borderTop: "1px solid #ddd",
  },
  filterContent: {
    padding: "20px",
  },
  categoriesContainer: {
    display: "flex",
    gap: "10px",
    flexWrap: "wrap",
    marginBottom: "10px",
  },
  categoryButton: {
    border: "1px solid #ddd",
    padding: "8px 12px",
    cursor: "pointer",
    fontSize: "14px",
    backgroundColor: "#fff",
    borderRadius: "5px",
  },
  selectedCategory: {
    backgroundColor: "#000",
    color: "#fff",
  },
  closeIcon: {
    marginLeft: "8px",
    fontSize: "12px",
  },
  actionButtonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    borderTop: "1px solid #ddd",
  },
  actionButton: {
    flex: 1,
    padding: "20px",
    fontSize: "14px",
    cursor: "pointer",
    backgroundColor: "#f4f4f4",
    border: "none",
    textAlign: "center",
  },
  overlay: {
    position: "fixed",
    top: 130,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    zIndex: 999,
  },
};

export default SearchFilters;
