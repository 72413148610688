import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom"; // To get the listing ID from the URL
import { useFirestore } from "../../firebase/FirestoreContext"; // Use Firestore context
import { onSnapshot, doc, collection, query, where, getDocs, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { currentProductPrice } from "../../functions/currentProductPrice";
import addToCart from "../../functions/addToCart";
import ListingPriceFormater from "../../elements/ListingPriceFormater";

import Header from "../../elements/Header";
import Modal from "../../elements/Modal"; // Assuming you have a modal component for web
import { useSwipeable } from "react-swipeable";

const Listing = ({ isDevMode }) => {
  const { listingID } = useParams(); // Get the listing ID from the URL
  const db = useFirestore(); // Get Firestore instance from context
  const auth = getAuth();
  const user = auth.currentUser;

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dosingGuide, setDosingGuide] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0); // Track currently selected image index
  const [viewImage, setViewImage] = useState(null); // Currently viewed image in modal
  const [quantity, setQuantity] = useState(0); // Track quantity
  const [priceInfo, setPriceInfo] = useState({});
  const [loadingPrice, setLoadingPrice] = useState(true);
  const [similarListingsState, setSimilarListingsState] = useState([]);
  const [similarListingsPrices, setSimilarListingsPrices] = useState([]);

  useEffect(() => {
    if (viewImage) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Re-enable scrolling
    }
    return () => {
      document.body.style.overflow = "auto"; // Cleanup in case of unmount
    };
  }, [viewImage]);
  //
  const handlers = useSwipeable({
    onSwipedLeft: () => handleNextImage(), // Swipe left to go to next image
    onSwipedRight: () => handlePrevImage(), // Swipe right to go to previous image
    preventDefaultTouchmoveEvent: true,
    trackMouse: true, // This enables swipe detection with a mouse as well (for desktop)
  });

  // Fetch product data and check if the product is in the cart
  useEffect(() => {
    if (!db) {
      console.error("Firestore instance is not initialized.");
      return;
    }

    const fetchSimilarListings = async (product_category) => {
      const listings = [];
      const queryRelatedSnapshot = query(collection(db, "Listings/ListingsList/DataBase"), where("product_category", "==", product_category));
      const relatedSnapshot = await getDocs(queryRelatedSnapshot);

      relatedSnapshot.forEach((doc) => {
        if (doc.id !== listingID && doc.data().listing_stock_amount !== 0) {
          listings.push(doc.data());
        }
      });

      setSimilarListingsState(listings);
      await fetchPricesForSimilarListings(listings);
    };

    const unsubscribe = onSnapshot(
      doc(db, `Listings/ListingsList/DataBase`, listingID),
      async (doc) => {
        if (doc.exists()) {
          const listingData = doc.data();

          if (listingData.dosing_guide) {
            try {
              const dosingGuideQuery = query(collection(db, `DosingGuides/DosingGuidesList/DataBase`), where("name", "==", listingData.dosing_guide));
              const querySnapshot = await getDocs(dosingGuideQuery);

              if (!querySnapshot.empty) {
                const dosingDocData = querySnapshot.docs[0].data();
                setDosingGuide(dosingDocData);
              } else {
                console.error("No matching dosing guide document found!");
              }
            } catch (error) {
              console.error("Error fetching dosing guide: ", error);
            }
          }

          fetchSimilarListings(listingData?.product_category);
          setData(listingData);
          fetchPriceInfo(listingData);
          setLoading(false);

          if (user) {
            checkProductInCart(listingID);
          }
        } else {
          console.error("No such document!");
          setLoading(false);
        }
      },
      (error) => {
        console.error("Error fetching document: ", error);
        setLoading(false);
      }
    );

    return () => unsubscribe(); // Clean up the subscription on unmount
  }, [db, listingID, isDevMode, user]);

  // Function to check if the product is already in the cart
  const checkProductInCart = async (listingID) => {
    if (!user) {
      console.error("User is not authenticated.");
      return;
    }

    try {
      const cartQuery = query(collection(db, "Carts/CartsList/DataBase"), where("user_id", "==", user.uid), where("status", "==", "ordering"));
      const cartSnap = await getDocs(cartQuery);

      if (!cartSnap.empty) {
        const cartID = cartSnap.docs[0]?.id;
        const itemRef = doc(db, `Carts/CartsList/DataBase/${cartID}/Items`, listingID);

        const itemSnap = await getDoc(itemRef);
        if (itemSnap.exists()) {
          setQuantity(itemSnap.data().quantity); // Set the quantity
        }
      }
    } catch (error) {
      console.error("Error checking product in cart:", error);
    }
  };

  const handleAddToCart = () => {
    setQuantity(1); // Initialize quantity to 1
    addToCart(listingID, user, db, 1);
  };

  const increaseQuantity = () => {
    setQuantity(quantity + 1);
    addToCart(listingID, user, db, 1);
  };

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    } else setQuantity(0);
    addToCart(listingID, user, db, -1);
  };

  // Image navigation handlers
  const handleNextImage = () => {
    if (selectedIndex < data.images.length - 1) {
      setSelectedIndex(selectedIndex + 1);
      setViewImage(data.images[selectedIndex + 1]);
    }
  };

  const handlePrevImage = () => {
    if (selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1);
      setViewImage(data.images[selectedIndex - 1]);
    }
  };

  const handleImageClick = (image, index) => {
    setViewImage(image);
    setSelectedIndex(index);
  };

  const fetchPriceInfo = async (productData) => {
    setLoadingPrice(true); // Start loading
    try {
      const result = await currentProductPrice(productData);
      setPriceInfo(result); // Store the price info
    } catch (error) {
      console.error("Error fetching product price:", error);
    }
    setLoadingPrice(false); // End loading
  };

  // Fetch prices for similar listings
  const fetchPricesForSimilarListings = async (listings) => {
    const prices = await Promise.all(
      listings.map(async (listing) => {
        const { finalPrice } = await currentProductPrice(listing);
        return finalPrice;
      })
    );
    setSimilarListingsPrices(prices);
  };

  // Render similar listings
  const renderSimilarListings = () => {
    if (similarListingsState.length === 0) {
      return <p>No similar products found.</p>;
    }

    return similarListingsState.map((listing, index) => {
      if (listing?.listing_stock_amount == 0) return null;
      return (
        <div key={index} style={{ padding: "10px", border: "1px solid #ddd", width: "200px" }}>
          <Link to={`/Listing/${listing?.seller_product_id}`} style={{ textDecoration: "none", color: "inherit" }}>
            <img
              src={listing?.images && listing?.images?.length > 0 ? listing.images[0] : "/images/no_product_image.png"}
              alt={listing.product_name}
              style={{ width: "160px", height: "100px", objectFit: "contain" }}
            />
            <p style={{ fontWeight: "500", padding: "2px 3px 3px 0", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
              {listing.product_name}
            </p>
            <p>${similarListingsPrices[index] || 0}</p>
          </Link>
        </div>
      );
    });
  };

  if (loading || !data) {
    return <div className="loading">Loading...</div>; // Loading indicator
  }
  return (
    <div style={styles.listingPage}>
      <Header stickyHeader />

      <div style={styles.container}>
        {/* Listing Header */}
        <div style={styles.listingHeader}>
          <div style={styles.brand.container}>
            <div style={styles.brand.name}>{data?.brand_name || ""}</div>
            <div style={styles.brand.shareButton}>
              <ShareButton
                listingUrl={`${window.location.protocol}//${window.location.hostname}${
                  window.location.port ? `:${window.location.port}` : ""
                }/Listing/${listingID}`}
              />
            </div>
          </div>
          <div style={styles.name.container}>
            <h1>{data?.product_name || "Product Name"}</h1>
            <div style={styles.rating}>
              <i data-feather={"star"} style={{ width: "16px", height: "16px", position: "relative", top: "1px", marginRight: "4px" }} />
              <span>
                {data?.rating?.rating ? parseFloat(data?.rating?.rating).toFixed(1) : "0.0"} ({data?.rating?.votes || 0})
              </span>
            </div>
          </div>
        </div>

        {/* Product Images Section */}
        <div style={styles.imageGallery}>
          {data?.images && data?.images?.length > 0 ? (
            <div style={styles.image}>
              {data?.images?.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Product image ${index + 1}`}
                  onClick={() => handleImageClick(image, index)}
                  style={{
                    ...styles.thumbnail,
                    ...(index === selectedIndex ? styles.selectedThumbnail : {}),
                    objectFit: "contain",
                  }}
                />
              ))}
            </div>
          ) : (
            <p style={{ ...styles.noImages }}>
              <img src="/images/no_product_image.png" alt="Product" style={{ width: "200px", height: "200px" }} />
            </p>
          )}

          {viewImage && (
            <Modal onClose={() => setViewImage(null)}>
              <div style={{ ...styles.modalContent }} {...handlers}>
                {" "}
                {/* Apply swipe handlers here */}
                <div onClick={() => setViewImage(null)} style={{ padding: "10px", opacity: ".5", alignSelf: "flex-end", cursor: "pointer" }}>
                  CLOSE X
                </div>
                {/* Image and Arrows */}
                <div style={{ width: "100%", flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
                  {selectedIndex > 0 && (
                    <button style={styles.leftArrow} onClick={handlePrevImage}>
                      &#8592;
                    </button>
                  )}

                  <img src={viewImage} alt="Selected Product" style={styles.modalImage} />

                  {selectedIndex < data.images.length - 1 && (
                    <button style={styles.rightArrow} onClick={handleNextImage}>
                      &#8594;
                    </button>
                  )}
                </div>
              </div>
            </Modal>
          )}
        </div>

        {/* Product Details Section */}
        <div style={styles.productDetails}>
          <p style={styles.label}>Description</p>
          <p>{data?.product_description || "No description available."}</p>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", paddingTop: "20px" }}>
            <div style={{ flex: 1 }}>
              <div style={styles.infoRow}>
                <p style={styles.label}>Category</p>
                <p>{data?.product_category || "N/A"}</p>
              </div>
              <div style={styles.infoRow}>
                <p style={styles.label}>Net Weight</p>
                <p>
                  {data?.net_weight} {data?.weight_units}
                </p>
              </div>

              <div style={styles.infoRow}>
                <p style={styles.label}>Number of Servings</p>
                <p>{data?.number_servings || "N/A"}</p>
              </div>
            </div>

            <div style={{ flex: 1 }}>
              <div style={styles.infoRow}>
                <p style={styles.label}>Strength</p>
                <p>{data?.strength || "N/A"}</p>
              </div>
              {/*
              {data?.product_type == "Plant" && (
                <div style={styles.infoRow}>
                  <p style={styles.label}>Effects</p>
                  <p>{data?.effects || "N/A"}</p>
                </div>
              )}
              {data?.product_type == "Magic" && (
                <div style={styles.infoRow}>
                  <p style={styles.label}>Strain Type</p>
                  <p>{data?.strain_type || "N/A"}</p>
                </div>
              )}*/}
              <div style={styles.infoRow}>
                <p style={styles.label}>Serving Size</p>
                <p>{data?.serving_size || "N/A"}</p>
              </div>

              <div style={styles.infoRow}>
                <p style={styles.label}>Dose per Serving</p>
                <p>{data?.dose_per_serving || "N/A"}</p>
              </div>
            </div>
          </div>

          <div style={{ marginTop: "20px", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <p style={styles.label}>Similar products ({similarListingsState && similarListingsState.length})</p>
            <p style={styles.label}></p>
          </div>
          <div style={{ ...styles.similarListing.container }}>{renderSimilarListings()}</div>

          {dosingGuide && (
            <>
              <h4>Dosing Guide</h4>
              {Object.entries(dosingGuide.guides).map(([key, value], index) => (
                <div key={index} style={styles.infoRow}>
                  <p style={styles.label}>{value.notes}:</p>
                  <p>{value.dose}</p>
                </div>
              ))}
            </>
          )}
          <div style={{ height: "100px" }} />
        </div>

        {/* Sticky Buy Button */}
        <div
          style={{
            ...styles.stickyFooter.container,
            backgroundColor: quantity === 0 ? "white" : "black",
            color: data?.listing_stock_amount == 0 ? "silver" : quantity === 0 ? "black" : "white",
          }}
        >
          <div style={styles.stickyFooter.leftContainer}>
            {quantity === 0 ? (
              <div style={{ ...styles.stickyFooter.pricingSection, display: "flex", flexDirection: "row", flex: 1 }}>
                <div>{priceInfo && data ? <ListingPriceFormater priceInfo={priceInfo} product={data} type={"prices"} /> : null}</div>
                <div
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    marginLeft: "10px",
                    display: "flex",
                    flex: 1,
                    width: "100%",
                  }}
                >
                  {priceInfo && data ? (
                    <ListingPriceFormater
                      counterTitle={"This deal ends in"}
                      priceInfo={priceInfo}
                      product={data}
                      type={"sooperdeal_counter_with_flames"}
                      containerStyle={{
                        paddingLeft: "10px",
                        alignItems: "flex-end",
                        height: "100%",
                        zIndex: 0,
                        fontSize: ".8rem",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        margin: "0",
                        backgroundColor: "transparent",
                      }}
                    />
                  ) : null}
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  fontSize: "12px",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div>
                  <span style={{ fontWeight: "700" }}>
                    {priceInfo && data ? <ListingPriceFormater priceInfo={priceInfo} product={data} type={"prices"} /> : null}
                  </span>
                </div>
                <div style={{ marginLeft: "15px", opacity: 0.5 }}>Added to bag!</div>
              </div>
            )}
          </div>
          <div
            style={{
              ...styles.stickyFooter.rightContainer,
              background: data?.listing_stock_amount == 0 ? "silver" : "black",
              cursor: data?.listing_stock_amount == 0 ? "default" : "pointer",
            }}
          >
            {quantity === 0 ? (
              <div style={{ ...styles.stickyFooter.addButton }} onClick={() => data?.listing_stock_amount != 0 && handleAddToCart()}>
                {data?.listing_stock_amount == 0 ? "Sold Out" : "Add to Bag"}
              </div>
            ) : (
              <div style={styles.quantityControls}>
                <div onClick={decreaseQuantity} style={styles.stickyFooter.quantityButton}>
                  -
                </div>
                <span style={styles.stickyFooter.quantity}>{quantity}</span>
                <div onClick={increaseQuantity} style={styles.stickyFooter.quantityButton}>
                  +
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const ShareButton = ({ listingUrl }) => {
  const handleShare = () => {
    // Check if the Web Share API is available
    if (navigator.share) {
      navigator
        .share({
          title: "Check out this listing!",
          url: listingUrl,
        })
        .then(() => console.log("Successfully shared"))
        .catch((error) => console.log("Error sharing", error));
    } else {
      // Fallback for browsers that do not support the Web Share API
      alert("Web Share API not supported in this browser.");
    }
  };

  return (
    <div
      style={styles.brand.shareButton}
      onClick={handleShare} // Attach click event to handle sharing
    >
      <i data-feather={"share"} style={{ width: "16px", height: "16px" }} />
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: "white",
  },
  /* Existing styles... */
  stickyFooter: {
    container: {
      position: "fixed",
      bottom: 0,
      width: "100%",
      backgroundColor: "#fff",
      boxShadow: "0 -1px 10px rgba(0, 0, 0, 0.1)",
      display: "flex",
      borderTop: ".2px solid #808080",
      flexDirection: "row",
      fontSize: "14px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    leftContainer: {
      padding: "14px 0",
      paddingLeft: "10px",
      flex: 1,
      display: "flex",
      justifyContent: "flex-start",
    },
    rightContainer: {
      height: "60px",
      paddingRight: "10px",
      paddingRight: "10px",
      display: "flex",
      justifyContent: "flex-end",
      cursor: "pointer",
      backgroundColor: "#000",
    },
    addButton: {
      color: "#fff",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      padding: "0px 20px",
    },
    pricingSection: {},
    offerPrice: {
      originalPrice: {
        color: "green",
      },
    },
    regularPrice: {
      color: "green",
    },
    quantityButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      fontSize: "20px",
      color: "white",
      padding: "0px 20px",
    },
    quantity: {
      padding: "0 15px",
      fontSize: "16px",
      color: "white",
      justifyContent: "center",
    },
  },
  //
  label: {
    fontSize: "12px",
    fontWeight: "100",
    marginBottom: "2px",
    color: "gray",
  },

  //
  rating: {
    marginTop: "10px",
  },
  //
  brand: {
    container: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "20px 10px 0px 10px",
    },
    name: {
      fontSize: "14px",
    },
    shareContainer: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
  },
  //
  imageGallery: {
    display: "flex",
    flexDirection: "row",
    overflowX: "auto",
    height: "210px",
    whiteSpace: "nowrap",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    marginBottom: "20px",
    maxWidth: "1000px",
    overflowX: "scroll",
    overflowY: "hidden", // Prevent vertical scrolling
    width: "100%", // Ensure the gallery fits the available width
    whiteSpace: "nowrap", // Keep images in a single row

    whiteSpace: "nowrap", // Ensure images stay in a single line
    scrollbarWidth: "none", // Hide scrollbar in Firefox
    msOverflowStyle: "none", // Hide scrollbar in IE and Edge
  },
  ".imageGallery::-webkit-scrollbar": {
    display: "none",
  },
  image: { paddingLeft: "10px", scrollbarWidth: "none", msOverflowStyle: "none" },
  thumbnail: {
    border: "1px solid #ddd",
    width: "80%",
    height: "100%",

    objectFit: "cover",
    cursor: "pointer",
    marginRight: "5px",
  },
  //
  name: {
    container: {
      padding: "10px",
    },
  },
  //
  productDetails: {
    padding: "0 10px ",
    fontSize: "14px",
  },
  additionalDetails: {
    padding: "0 10px ",
    fontSize: "14px",
    paddingBottom: "100px",
  },
  infoRow: {
    marginBottom: "10px",
  },

  quantityControls: {
    display: "flex",
    alignItems: "center",
  },
  quantityButton: {
    backgroundColor: "#ddd",
    border: "none",
    padding: "10px 20px",
    cursor: "pointer",
    fontSize: "20px",
  },
  quantity: {
    margin: "0 10px",
    fontSize: "16px",
  },
  //
  noImages: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  //mainImage
  modalContent: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    backgroundColor: "white",
    borderRadius: "15px",
    touchAction: "none",
    margin: "20px",
  },
  modalImage: {
    width: "100%", // Adjust this to fit the container without zoom
    height: "auto",
    objectFit: "contain", // Ensures that the image fits within the modal without zooming
  },
  leftArrow: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    color: "white",
    border: "none",
    padding: "10px",
    fontSize: "20px",
    cursor: "pointer",
    height: "50px",
    width: "40px",
    position: "absolute",
    left: 0,
  },
  rightArrow: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    color: "white",
    border: "none",
    padding: "10px",
    fontSize: "20px",
    cursor: "pointer",
    height: "50px",
    width: "40px",
    position: "absolute",
    right: 0,
  },
  //
  similarListing: {
    container: {
      display: "flex",
      flexDirection: "row",
      overflowX: "auto", // Enable horizontal scrolling
      whiteSpace: "nowrap",

      // Hide scrollbar (Cross-browser support)
      scrollbarWidth: "none", // Firefox: Hide scrollbar
      msOverflowStyle: "none", // IE and Edge: Hide scrollbar
    },
  },
  // Hide scrollbar for WebKit-based browsers (Chrome, Safari, Opera)
  ".similarListing-container::-webkit-scrollbar": {
    display: "none", // Hide scrollbar in WebKit-based browsers
  },
};

export default Listing;
