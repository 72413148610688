import React, { useState, useContext, useEffect, useCallback } from "react";
import Header from "../../elements/Header";
import SquarePaymentForm from "../../elements/SquarePaymentForm";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { useFirestore } from "../../firebase/FirestoreContext";
import { doc, updateDoc, deleteDoc } from "firebase/firestore";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
// Stripe
import { loadStripe } from "@stripe/stripe-js";

// Load Stripe
const stripePromise = loadStripe("pk_live_51Q7NV6BmPhwnfvGw5LHXyRuz5pnpQcOU3UEgx5WFI2UiMONqNq461OPuufnhgyqtgs8sDusOzQPCUJKVGiaBCXgg00SQHwT9Hg");

const Checkout = () => {
  const location = useLocation();
  const { cartID, cart_sooperstock_ID, items, subtotal, shippingCostsubtotal } = location.state || {};
  const totalAmount = (parseFloat(subtotal) + shippingCostsubtotal).toFixed(2);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("Stripe"); // Default is Stripe
  const [purchaseButtonDisabled, setPurchaseButtonDisabled] = useState(true);

  // User context
  const { user } = useContext(UserContext);
  const db = useFirestore();

  // Addresses
  const [addresses, setAddresses] = useState([]);
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(0); // Default to the first address
  const [showAddressOptions, setShowAddressOptions] = useState(false);
  const [showAddNewForm, setShowAddNewForm] = useState(false);

  // New address form states
  const [address_name, setAddressName] = useState("");
  const [address_autocomplete, setAddressAutocomplete] = useState(null);
  const [address_optional, setAddressOptional] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (user && user.addresses && user.addresses.length > 0) {
      setAddresses(user.addresses);
    } else {
      setAddresses([]);
    }
  }, [user]);

  // Stripe
  const fetchCheckoutSessionId = useCallback(() => {
    const payload = {
      lineItems: items.map((item) => ({
        name: item.product_name, // Change from product_name to name
        description: item.description,
        amount: Math.round(item.finalPrice * 100), // Price in cents
        currency: "usd",
        quantity: item.quantity,
      })),
      client_reference_id: cartID,
      totalAmount: totalAmount * 100,
    };
    if (user?.email) {
      payload.customer_email = user.email;
    }

    payload.lineItems.push({
      name: "Shipping", // Ensure consistent naming
      description: "Shipping",
      amount: Math.round(shippingCostsubtotal * 100),
      currency: "usd",
      quantity: 1,
    });

    return fetch("https://us-central1-sooperstock-app.cloudfunctions.net/api/payment/stripeCreateCheckoutSession", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        return { sessionId: data.sessionId, paymentIntentId: data.id, paymentData: data };
      })
      .catch((error) => console.error("Error fetching sessionId:", error));
  }, [items, totalAmount]);

  const handleStripeCheckout = async () => {
    setIsLoading(true); // Start loading

    const { sessionId, paymentIntentId, paymentData } = await fetchCheckoutSessionId();

    if (sessionId) {
      const stripe = await stripePromise;

      // Store both stripe session ID and payment intent ID in cart document
      const docRef = doc(db, "Carts/CartsList/DataBase", cartID);
      await updateDoc(docRef, {
        stripeSessionId: sessionId,
        stripePaymentIntentId: paymentIntentId,
        stripePaymentData: paymentData,
        shipmentAddress: addresses[selectedAddressIndex],
      });

      const { error } = await stripe.redirectToCheckout({ sessionId });

      if (error) {
        console.error("Stripe Checkout error:", error);
        setIsLoading(false);
      }
    } else {
      console.error("Failed to retrieve Checkout session ID.");
      setIsLoading(false);
    }
  };

  const handlePaymentSuccess = async () => {
    const docRef = doc(db, "Carts/CartsList/DataBase", cartID);
    await deleteDoc(docRef);

    console.log("Payment succeeded!");
  };

  const handlePaymentFailure = () => {
    console.log("Payment failed.");
  };

  useEffect(() => {
    validatePurchaseButton();
  }, [selectedPaymentMethod, addresses]);

  const validatePurchaseButton = () => {
    console.log("Validating button:", selectedPaymentMethod, addresses.length > 0);
    if (selectedPaymentMethod && addresses.length > 0) {
      setPurchaseButtonDisabled(false); // Enable buttons if conditions are met
    } else {
      setPurchaseButtonDisabled(true); // Keep buttons disabled otherwise
    }
  };

  const toggleAddressOptions = () => {
    if (addresses.length <= 1) {
      // If there's no other address, show the add new form immediately
      setShowAddNewForm(true);
    } else {
      setShowAddressOptions(!showAddressOptions);
      setShowAddNewForm(false); // Ensure add new form is not visible
    }
  };

  const handleAddressSelect = (index) => {
    setSelectedAddressIndex(index);
    setShowAddressOptions(false);
  };

  const handleAddNewAddress = () => {
    setShowAddNewForm(true);
    setShowAddressOptions(false); // Ensure address options are hidden
  };

  const handleSaveNewAddress = async () => {
    // Ensure user is defined
    if (!user || !user.uid) {
      console.error("User is not defined.");
      return;
    }

    const userID = user.uid;
    const userRef = doc(db, `Users/UsersList/DataBase`, userID);

    // Extract address details
    const formattedAddress = address_autocomplete ? address_autocomplete.label : "";
    const placeId = address_autocomplete ? address_autocomplete.value.place_id : "";

    const newAddressData = {
      address_name: address_name || "",
      address: formattedAddress,
      address_optional: address_optional || "",
      place_id: placeId,
    };

    const updatedAddresses = [...addresses, newAddressData];

    try {
      // Update addresses in Firestore
      await updateDoc(userRef, { addresses: updatedAddresses });
      setAddresses(updatedAddresses);
      setSelectedAddressIndex(updatedAddresses.length - 1); // Select the new address
      setShowAddNewForm(false);
      // Reset form fields
      setAddressName("");
      setAddressAutocomplete(null);
      setAddressOptional("");
    } catch (error) {
      console.error("Error saving new address:", error);
    }
  };

  // If user is not defined, show loading
  if (!user || !user.uid) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div style={{ backgroundColor: "white" }}>
      <Header />
      <div style={styles.checkoutContainer}>
        <h2 style={styles.orderTitle}>
          Place your order <span style={styles.orderNumber}>#{cart_sooperstock_ID}</span>
        </h2>

        {/* Delivery Information */}
        <div style={styles.address}>
          <h4 style={styles.headerText}>Delivery to</h4>
          {addresses.length > 0 ? (
            <div style={styles.addressBox}>
              <h4 style={styles.nameText}>{addresses[selectedAddressIndex].address_name}</h4>
              <p style={styles.addressText}>{addresses[selectedAddressIndex].address}</p>
              <p style={styles.addressText}>{addresses[selectedAddressIndex].address_optional}</p>
            </div>
          ) : (
            <p>Please add an address.</p>
          )}
          <button style={styles.linkButton} onClick={toggleAddressOptions}>
            Use a different address
          </button>

          {/* Address Options */}
          {showAddressOptions && (
            <>
              {addresses.length > 1 && (
                <div style={{ ...styles.addressOptions, width: "100%", cursor: "pointer" }}>
                  {addresses.map((addr, index) => {
                    if (index !== selectedAddressIndex) {
                      return (
                        <div key={index} style={styles.addressBox} onClick={() => handleAddressSelect(index)}>
                          <h4 style={styles.nameText}>{addr.address_name}</h4>
                          <p style={styles.addressText}>{addr.address}</p>
                          <p style={styles.addressText}>{addr.address_optional}</p>
                          <p style={styles.changeAddressButton}>Use this address</p>
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
              <button style={styles.linkButton} onClick={handleAddNewAddress}>
                Add new address
              </button>
            </>
          )}

          {/* Add New Address Form */}
          {showAddNewForm && (
            <div style={{ ...styles.editForm, width: "100%" }}>
              <label style={styles.label}>Please enter the recipient’s name</label>
              <input
                type="text"
                value={address_name}
                onChange={(e) => setAddressName(e.target.value)}
                style={styles.input}
                placeholder="Your Name (First Last)"
              />

              <label style={styles.label}>Please enter a delivery address</label>
              <div style={styles.autocompleteContainer}>
                <GooglePlacesAutocomplete
                  apiKey="YOUR_GOOGLE_MAPS_API_KEY"
                  selectProps={{
                    value: address_autocomplete,
                    onChange: setAddressAutocomplete,
                    placeholder: "Search for an address",
                    styles: {
                      control: (provided) => ({
                        ...provided,
                        fontSize: "14px",
                        padding: "0px",
                        margin: "0px",
                        boxSizing: "border-box",
                        minHeight: "40px",
                      }),
                      input: (provided) => ({
                        ...provided,
                        fontSize: "14px",
                        padding: "0px 10px",
                        margin: "0px",
                        boxSizing: "border-box",
                      }),
                      valueContainer: (provided) => ({
                        ...provided,
                        padding: "0px 10px",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        fontSize: "14px",
                        margin: "0px",
                      }),
                      option: (provided) => ({
                        ...provided,
                        fontSize: "14px",
                      }),
                      dropdownIndicator: (provided) => ({
                        ...provided,
                        display: "none",
                      }),
                      // Remove the indicator separator (line next to the arrow)
                      indicatorSeparator: (provided) => ({
                        display: "none",
                      }),
                    },
                  }}
                  autocompletionRequest={{
                    componentRestrictions: {
                      country: ["us", "be"],
                    },
                  }}
                />
              </div>

              <input
                type="text"
                value={address_optional}
                onChange={(e) => setAddressOptional(e.target.value)}
                style={styles.input}
                placeholder="Apt or building number (optional)"
              />

              <button style={styles.saveButton} onClick={handleSaveNewAddress}>
                Save address
              </button>
              <button style={styles.cancelButton} onClick={() => setShowAddNewForm(false)}>
                Cancel
              </button>
            </div>
          )}
        </div>

        <div style={{ padding: "20px 0px 20px 0px", color: "green", fontWeight: "400", borderTop: "1px solid #ddd" }}>
          {shippingCostsubtotal !== 0 && "FREE shipping for orders over $100"}
        </div>

        {/* Payment Method */}
        <div style={{ ...styles.section }}>
          <div style={{ display: "none" }}>
            <h4 style={styles.sectionTitle}>Payment method</h4>
            <div>
              <label>
                <input type="radio" value="Stripe" checked={selectedPaymentMethod === "Stripe"} onChange={() => setSelectedPaymentMethod("Stripe")} />
                Pay with Stripe
              </label>
              <label style={{ marginLeft: "10px" }}>
                <input type="radio" value="Square" checked={selectedPaymentMethod === "Square"} onChange={() => setSelectedPaymentMethod("Square")} />
                Pay with Square
              </label>
            </div>
          </div>

          {/* Conditional rendering for Stripe or Square based on selected payment method */}
          {selectedPaymentMethod === "Stripe" ? (
            <>
              <div style={styles.summarySection}>
                <div style={styles.summaryRow}>
                  <span>Items:</span>
                  <span>${subtotal.toFixed(2)}</span>
                </div>
                <div style={styles.summaryRow}>
                  <span>Shipping:</span>
                  <span style={styles.greenText}>${shippingCostsubtotal ? shippingCostsubtotal.toFixed(2) : "0.00"}</span>
                </div>
                <div style={styles.subtotalRow}>
                  <strong>Subtotal:</strong>
                  <strong>${totalAmount}</strong>
                </div>
              </div>
              <button
                onClick={handleStripeCheckout}
                disabled={addresses.length === 0 || isLoading} // Ensure there is an address selected
                style={{
                  ...styles.stripeButton,
                  width: "100%",
                  padding: 13,
                  backgroundColor: isLoading ? "gray" : "black",
                  color: "white",
                  fontSize: "1rem",
                  border: 0,
                }} // New button styling
              >
                {isLoading ? (
                  <span>Loading...</span> // Show loading spinner or text when loading
                ) : (
                  "Purchase"
                )}
              </button>
            </>
          ) : (
            <SquarePaymentForm
              orderAmount={totalAmount}
              onSuccess={handlePaymentSuccess}
              onFailure={handlePaymentFailure}
              cartID={cartID}
              cart_sooperstock_ID={cart_sooperstock_ID}
              items={items}
              subtotal={subtotal}
              totalAmount={totalAmount}
              address={addresses[selectedAddressIndex]} // Pass the selected address to the payment form
            />
          )}
        </div>
      </div>
    </div>
  );
};

const styles = {
  checkoutContainer: {
    padding: "20px",
    margin: "0 auto",
    fontFamily: "'Inter', sans-serif",
  },
  orderTitle: {
    fontSize: "18px",
    marginBottom: "20px",
  },
  orderNumber: {
    fontWeight: "bold",
  },
  section: {
    marginBottom: "30px",
  },
  sectionTitle: {
    textTransform: "uppercase",
    fontSize: "12px",
    color: "gray",
    marginBottom: "10px",
  },

  recipient: {
    fontWeight: "bold",
    display: "block",
    marginBottom: "5px",
  },
  address: {
    marginBottom: "10px",
  },
  changeAddressButton: {
    color: "blue",
    background: "none",
    border: "none",
    cursor: "pointer",
    fontSize: "14px",
    textDecoration: "underline",
  },
  paymentMethodBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
  paymentMethodInfo: {
    display: "flex",
    flexDirection: "column",
  },
  paymentMethodDescription: {
    color: "gray",
    fontSize: "12px",
  },
  radioButton: {
    marginTop: "10px",
    height: "20px",
    width: "20px",
  },
  summarySection: {
    borderTop: "1px solid #ddd",
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  summaryRow: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
    fontSize: "14px",
  },
  subtotalRow: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
    fontSize: "16px",
    fontWeight: "bold",
  },
  greenText: {
    color: "green",
  },
  purchaseButton: {
    width: "100%",
    padding: "15px",
    backgroundColor: "gray",
    color: "white",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
    marginTop: "20px",
  },
  disclaimer: {
    fontSize: "12px",
    color: "gray",
    textAlign: "center",
    marginTop: "10px",
  },
  addressOptions: {
    margin: "10px 0px",
    display: "flex",
    flexDirection: "column",
  },
  addressOption: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  addNewAddressButton: {
    color: "blue",
    background: "none",
    border: "none",
    cursor: "pointer",
    fontSize: "14px",
    textDecoration: "underline",
  },
  //
  address: {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
    backgroundColor: "#fff",
    alignItems: "flex-start",
  },
  headerText: {
    fontSize: "14px",
    color: "gray",
    marginBottom: "10px",
  },
  addressBox: {
    width: "100%",
    border: "1px solid #ddd",
    padding: "15px",
    textAlign: "left",
    marginBottom: "0px",
  },
  nameText: {
    fontSize: "16px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  addressText: {
    fontSize: "14px",
    margin: "2px 0",
  },
  linkButton: {
    fontSize: "14px",
    color: "blue",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    textDecoration: "underline",
    marginBottom: "20px",
    marginTop: "10px",
  },
  editForm: {
    display: "flex",
    flexDirection: "column",
  },
  label: {
    fontSize: "14px",
    color: "gray",
    marginBottom: "5px",
  },
  input: {
    fontSize: "14px",
    padding: "10px",
    marginBottom: "15px",
    border: "1px solid #ddd",
  },
  autocompleteContainer: {
    marginBottom: "15px",
  },
  saveButton: {
    backgroundColor: "black",
    color: "white",
    height: "50px",
    marginTop: "10px",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
    textAlign: "center",
  },
  cancelButton: {
    fontSize: "14px",
    color: "black",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    textDecoration: "underline",
    marginTop: "15px",
  },
};

export default Checkout;
