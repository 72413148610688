import React, { useState, useEffect } from "react";
import Header from "../../elements/Header";

const Legal = ({ isDevMode }) => {
  return (
    <div>
      <Header />
      <div style={{ padding: "20px" }}>
        <div>
          TERMS OF SERVICE
          <br />
          Sooperstock, Inc.
          <br />
          Effective Date: 10/01/2024
        </div>
        <br />
        <br />
        <h4>1. Website Terms of Service</h4>
        <span>
          This website (www.sooperstock.com) and all related content, mobile applications, and associated services (collectively, the “Website”), is owned and
          operated by Sooperstock, Inc. or its local subsidiary (“Sooperstock,” “us” or “we”). We have adopted these Terms of Service (“Terms of Service” or
          “Terms”) to let you know about your rights and obligations when you use this Website or purchase any of our products (“Products”) on the Website from
          Sooperstock or any of its subsidiaries. By using this Website, or purchasing Products on this Website, you are consenting to these Terms. If you do
          not agree to these Terms you may not use this Website or purchase Products from the Website.
          <br /> <br />
          Sooperstock may, and reserve the right to, from time to time, modify, limit, change, discontinue, or replace this Website and/or these Terms of
          Service at any time. In the event Sooperstock modifies, limits, changes, or replaces the Website or these Terms of Service, your continued use
          thereafter constitutes your agreement to such modification, limitation, change, or replacement.
          <br /> <br />
          It is your responsibility to review these Terms of Service on a regular basis to keep yourself informed of any modifications, limitations, changes, or
          replacements. You must be at least twenty-one (21) years of age (“Minimum Age”) to access this Website or purchase any Products on this Website. You
          warrant and agree that you are at least the Minimum Age. Sooperstock does not warrant or guarantee that compliance with these Terms of Service will be
          sufficient to comply with your obligations under the applicable laws where you reside or where you use the Website. It is your responsibility to check
          and understand the local laws in your jurisdiction to determine whether you are of the legal minimum age to purchase and use our Products or access
          our Website.
          <br /> <br />
          Please read these Terms of Service carefully to ensure that you understand each provision. These Terms contain a mandatory individual arbitration and
          class action/jury trial waiver provision that requires the use of arbitration on an individual basis to resolve disputes, rather than jury trials or
          class actions.
        </span>{" "}
        <br /> <br />
        <h4>2. Eligibility and Age Verification</h4>
        <span>
          These Terms are a contract between you and Sooperstock. You must be at least the Minimum Age to use this Website or register or an account. Any use of
          an account on the Website by anyone under the Minimum Age is strictly prohibited and in violation of these Terms. If we determine or have reason to
          believe that you are under the Minimum Age, we will terminate your account. The Website contains information about and the opportunity to purchase our
          Products. Some Products, including cannabis vaporizers and consumable hemp products, are governmentally regulated and require you to be at least the
          Minimum Age in order to purchase and use such products. In order for us to verify that you are at least the Minimum Age, an age verification check
          will occur before you can complete the purchase of any Products. We may use the information you provide to verify your age and we may deny or cancel
          your purchase if we cannot verify that you are at least the Minimum Age. Only persons who we have verified are at least the Minimum Age can purchase
          Products and participate in any promotions or offers for Products from the Website.
        </span>{" "}
        <br /> <br />
        <h4>3. Safety Acknowledgement</h4>
        <span>
          No product should be considered 100% safe for consumption. We encourage you to do your own research regarding any and all products and what is right
          for you. If you have any health concerns about the use of a product for any purpose, we recommend that you consult with your physician. The amount of
          material delivered per serving will vary based on the material used, the method of consumption by the user, and the individual user's consumption
          behavior. Those with health issues that may be exacerbated by the use of any product should consult with their physicians before using.
          <br /> <br />
          Federally compliant consumable hemp products contain varying levels of THC, an intoxicating substance, but not more than 0.3% by volume. All
          hemp-derived products are to be consumed as intended in accordance with manufacturer instructions. The Food and Drug Administration (“FDA”) has not
          evaluated consumable hemp Products for safety, effectiveness, or quality. Because these hemp-derived products contain cannabinoids, you should consult
          a licensed healthcare professional if pregnant, breast feeding, or currently taking medications. Do not drive or operate heavy machinery when using.
          These products may be addictive or habit forming. Any statements on this Website or any materials or Products we distribute or sell have not been
          evaluated by the FDA. Neither the Products nor the ingredients in any of the products linked to this Website have been approved or endorsed by the FDA
          or any regulatory agency. The Products are not intended to diagnose, treat, cure or prevent any disease. The information on this Website or other
          materials we may provide to you are designed for educational purposes only and are not intended to be a substitute for informed medical advice or
          care. This information should not be used to diagnose or treat any health problems or illnesses without consulting a doctor. If you are pregnant,
          nursing, taking medication, or have a medical condition, we suggest consulting with a physician before using any of our Products.
        </span>{" "}
        <br /> <br />
        <h4>4. Account Registration</h4>
        <span>
          In order to make a purchase from the Website you must complete the registration process to obtain a user account. You will be required to provide your
          name, address, e-mail address, phone number and age verification. You must provide complete and accurate information about yourself during the
          registration process and you have an ongoing obligation to update this information if and when it changes. We will handle your information consistent
          with our Privacy Policy, which is incorporated into these Terms of Service by reference.
          <br /> <br />
          When you create an account, you will be required to create a user ID and password. You agree that you will never share your user ID and password with
          anyone else for any reason. You agree that only you will use your account. You agree to contact Sooperstock immediately if you become aware of
          unauthorized access to your account. You agree that you are solely responsible for all activity that takes place in connection with your account and
          you agree to indemnify and hold harmless Sooperstock from any damages that arise out of or in relation to use of your account. You agree that you will
          not create more than one account. By registering and obtaining an account you affirm that you will follow these Terms and your registration
          constitutes your consent to enter into agreements with us electronically.
        </span>{" "}
        <br /> <br />
        <h4>5. Termination of Account</h4>
        <span>
          We reserve the right to restrict access to, suspend, disable, terminate, and/or delete your account for any reason that we deem necessary, or for no
          reason. In the event your account is suspended, disabled, terminated, or deleted, your only option is to request reinstatement of your account by
          contacting Sooperstock via email. You may not open alternate accounts.
        </span>{" "}
        <br /> <br />
        <h4>6. Electronic Communication – www.sooperstock.com Account Specific</h4>
        <span>
          With the creation of an account you agree that we may send to you electronic notices or other communication regarding Sooperstock, our Products, and
          this Website. These types of electronic communications will be sent to the email address that was provided during registration and/or the email
          address associated with your account. The contents of any communication are effective when sent, regardless of when you receive or whether you read
          the communication. You can stop receiving these notices and communications at any time with the deactivation of your account.
        </span>{" "}
        <br /> <br />
        <h4>7. Electronic Communication – Marketing Communication</h4>
        <span>
          By providing your email address and subscribing to the Sooperstock Newsletter, you will receive electronic communications and exclusive offers. These
          exclusive offers may be unavailable depending on the jurisdiction in which you live and local regulations. All offers are Void Where Prohibited. The
          email address provided will be used solely by Sooperstock and will not be sold to, shared with, or otherwise disclosed to other parties, except as
          permitted under our Privacy Policy. You can choose to stop receiving these offers at any time by filling out an unsubscribe request, using the
          unsubscribe option on communications or contacting us via email.
        </span>{" "}
        <br /> <br />
        <h4>8. Communications with Sooperstock</h4>
        <span>
          You are encouraged to forward comments or other communications to Sooperstock by submitting an email. You agree that you will not transmit content to
          Sooperstock that is illegal, obscene, threatening, defamatory, invasive of privacy, infringing of intellectual property rights, or otherwise injurious
          to third parties, or objectionable and does not consist of or contain software viruses, political campaigning, commercial solicitation, chain letters,
          mass mailings, or any form of “spam”. You agree that you will not use a false e-mail address, impersonate a third party person or entity, or otherwise
          mislead Sooperstock as to the origin of a communication. Communications violating the restrictions of this paragraph shall constitute a violation of
          these Terms, and may result in the termination of your account at the sole discretion of Sooperstock.
          <br /> <br />
          Any material or communication transmitted by you to this Website, including communications via email or a Support Ticket, will not be treated as
          confidential. By submitting any content to us through the Website, you grant us a non-exclusive, irrevocable, royalty free, worldwide, and perpetual
          license to use that content for any purpose. We may reproduce, prepare derivative works of, distribute, perform publicly, transmit, broadcast, or
          display the content you submit. Any ideas, concepts, or other materials transmitted by you to Sooperstock may be used in any manner, including
          reproduction, transmission, publication or broadcast without compensation. We have the right, at our sole discretion, to edit or refuse to post
          content submitted by you. The provisions of this paragraph will survive the termination of these Terms and for the maximum period permitted under
          applicable law.
        </span>{" "}
        <br /> <br />
        <h4>9. Payment</h4>
        <span>
          You agree to pay for all purchases made on the Website. Except where noted otherwise, the prices displayed for Products represent the full retail
          price listed on the Product itself. All prices are quoted and payable in US dollars, regardless of where an order is placed or shipped. Foreign
          exchange rates apply and may depend on the date your order was placed.
          <br /> <br />
          Your purchases may also be subject to sales tax, VAT, or other taxes or duties at the point of sale where applicable. Payment of taxes and duties due
          are your responsibility unless such sales taxes or duties are collected by Sooperstock and/or its subsidiaries in which case Sooperstock will submit
          your taxes and/or duties to the appropriate authority on your behalf. Sooperstock and/or its subsidiaries shall not be responsible for failure to pay
          taxes or duties you owe, unless Sooperstock and/or its subsidiaries have collected such taxes or duties at the time of the purchase.
          <br /> <br />
          In the event that you dispute the amount or validity of any payments made to Sooperstock or its subsidiaries through this Website, you must notify
          Sooperstock in writing, within ten days of payment, of any such dispute by mail at the address listed below or by email at support@sooperstock.com.
          You expressly agree that your failure to notify Sooperstock of any dispute within five days of payment will constitute your express waiver of any
          claims related to the disputed payment.
          <br /> <br />
          You agree that you will pay all costs and expenses of collection, including attorneys’ fees, incurred by Sooperstock or its subsidiaries in the event
          of failure to make payment.
        </span>{" "}
        <br /> <br />
        <h4>10. Cancellation Policy</h4>
        <span>
          Orders submitted cannot be canceled once the shipping order has been generated. Generally, shipping orders are generated within one (1) hour from the
          time that the purchase was submitted. To cancel a purchase placed before shipping information is generated, please contact Sooperstock Support via
          email as soon as possible.
        </span>{" "}
        <br /> <br />
        <h4>11. Return Policy for Devices and Accessories</h4>
        <span>
          The following policy does not apply to any consumable product.
          <br /> <br />
          Sooperstock will only accept returns and/or issue any credit or refund for purchases made through the Website and in no event will Sooperstock accept
          return of and/or issue any refund for purchases made other than through the Website, including purchases made from an authorized Sooperstock partner.
          Products purchased through an authorized Sooperstock partner must be returned and refunded through the location from which the products were
          purchased.
          <br /> <br />
          You have the right to withdraw from the purchase agreement with Sooperstock and to return any new unused item purchased through the Website for a
          credit or refund without giving any reason up to ten (10) days following the invoice of the item purchased. To make a return in this way you must
          notify us by contacting Sooperstock via email. If you successfully complete a return, Sooperstock will reimburse all payments received from you for
          the returned items within fourteen (14) days from the day we receive and validate the return of the item.
          <br /> <br />
          If you elect to withdraw, you must return the new, unused item purchased to Sooperstock within ten (10) days of notifying us of your election. We may
          withhold reimbursement until we have received the purchased item. No credits or refunds will be issued for items not returned within ten (10) days of
          notifying Sooperstock that you would like to return the items.
          <br /> <br />
          You agree that you will be responsible for the cost of return shipment in the event you elect to withdraw from the purchase agreement and to return an
          item for a credit or refund. Because of the nature of the products sold through the Website, for sanitary reasons, Sooperstock accepts returns of and
          will issue refunds only for new and unused products. If the tamper proof seal is broken or original tags removed, we will be unable to accept the
          returned product and we will not issue a credit or refund.
          <br /> <br />
          California Residents. The provider of services is set forth herein. If you are a California resident, in accordance with Cal. Civ. Code §1789.3, you
          may report complaints to the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs by
          contacting them in writing at 1625 North Market Blvd., Suite N 112 Sacramento, CA 95834, or by telephone at (800) 952-5210 or (916) 445-1254 or{" "}
          <a href="https://www.dca.ca.gov/consumers/complaints/consumer.shtml">https://www.dca.ca.gov/consumers/complaints/consumer.shtml</a>.
        </span>{" "}
        <br /> <br />
        <h4>12. Return Policy for Consumable Products</h4>
        <span>
          The following policy only applies to consumable hemp Products.
          <br /> <br />
          All sales of consumable hemp Products are made by Sooperstock or its partners. All sales of consumable hemp Products are final and neither Sooperstock
          nor its subsidiaries will accept returns or issue refunds for purchases of consumable hemp products whether made through the Website or an authorized
          retailer.
          <br /> <br />
          If you have questions or concerns about your purchase of consumable hemp Products you may contact Sooperstock via email.
        </span>{" "}
        <br /> <br />
        <h4>13. Damaged/Incomplete/Incorrect Device & Accessory Orders</h4>
        <span>
          You have a legal guarantee of conformity in connection with items purchased through the Website. You agree to immediately inspect any goods purchased
          upon receipt. Please report damaged, incorrect, or incomplete orders to us immediately. If you received an order shipped from Sooperstock or its
          subsidiaries that is damaged, incorrect, or incomplete, please contact Sooperstock Support via email immediately to explain the problem with your
          order and to request a credit or replacement goods, if applicable. Sooperstock may require you to return damaged items, and you will be responsible
          for the cost of return shipment unless waived by Sooperstock on a case-by-case basis. Sooperstock does not repair damaged devices, nor does
          Sooperstock provide spare parts for repair.
        </span>{" "}
        <br /> <br />
        <h4>14. Product Resale or Other Exploitation Prohibited</h4>
        <span>
          You agree not to sell, resell, distribute or make available to others, or otherwise use or exploit for any commercial purposes (including, without
          limitation, in contests, sweepstakes and/or giveaways) any portion of any Products or services purchased from this Website or otherwise obtained from
          Sooperstock, its subsidiaries or a Sooperstock representative without prior written permission of an authorized representative of Sooperstock. Any
          person or entity that sells, resells, distributes or makes available to others, or otherwise uses or exploits any Products or services for commercial
          purposes without such prior written permission will void the product warranty and may be subject to civil action and/or criminal penalty, as
          prescribed by law.
        </span>{" "}
        <br /> <br />
        <h4>15. Compliance with Law; Indemnification</h4>
        <span>
          You agree that your use of any Products and/or Sooperstock services will comply at all times with all applicable laws and regulations, in all relevant
          jurisdictions, including without limitation laws and regulations related to product use, resale, and marketing, advertising, and/or other forms of
          promotion.
          <br /> <br />
          You agree to indemnify, defend, and hold harmless Sooperstock and its subsidiaries, agents, licensors, managers, and other affiliated companies, and
          their employees, contractors, agents, officers and directors, from and against any and all claims, liabilities, damages, losses, obligations, costs or
          debt, expenses (including reasonable attorney’s fees), regulatory penalties and enforcement actions arising out of or in connection with: (i) your use
          of and access to any Sooperstock Products or services, or this Website, including any data or content transmitted or received by you; (ii) your
          violation of any term of these Terms, including without limitation your breach of any of the representations and warranties herein or your obligations
          under this Section 15; (iii) your violation of any third-party right, including without limitation any right of privacy or intellectual property
          rights; (iv) your violation of any applicable law, rule or regulation; (v) any content that is submitted via your account including without limitation
          misleading, false, or inaccurate information; (vi) your willful misconduct; or (vii) any other party’s access and use of the Website with your unique
          username, password or other appropriate security code.
        </span>{" "}
        <br /> <br />
        <h4>16. Counterfeiting</h4>
        <span>
          The fight against unauthorized sales and counterfeiting is a long-term commitment to quality and service that is taken seriously by Sooperstock. We
          advise consumers to be cautious when purchasing products from unauthorized resellers as the quality, reliability and safety of these products is
          uncertain and in some cases, may expose the user to serious dangers not encountered with genuine Products.
          <br /> <br />
          Please contact Sooperstock Support to learn more about Authorized Retailers. Any Product purchased outside of our exclusive sales network runs the
          risk of being counterfeit and is not guaranteed under our Warranty, incorporated into these Terms of Service by reference. Sooperstock shall have no
          responsibility or liability for any Products purchased outside of our Website and/or Authorized Retailers.
          <br /> <br />
          Sooperstock will not assist with resolving issues pertaining to the purchase of counterfeit products nor will Sooperstock provide refunds for such
          purchases.
        </span>{" "}
        <br /> <br />
        <h4>17. Third Party Links</h4>
        <span>
          This Website may contain links to third-party websites. Sooperstock does not intend these links to be viewed as an endorsement of those websites or
          their content. We have not reviewed all of these third-party websites and do not control and are not responsible for any of these sites or their
          content. If you use these links, you will leave this Website. If you decide to access third-party websites, you do so at your own risk. Different
          terms of service may apply to any third-party website.
        </span>{" "}
        <br /> <br />
        <h4>18. Privacy Statement</h4>
        <span>
          For more information about how we use and protect your personal information you may provide through our Website, please click here to review the
          Website’s Privacy Policy. Note that Sooperstock reserves the right to change its Privacy Policy at any time without notice.
          <br /> <br />
          As required by law and to ensure Products are provided only to purchasers who are of legal purchase age, Sooperstock and its subsidiaries may be
          required to report eligible sales and sales data to the relevant state or Federal authority. The information reported may include your name, the
          Products ordered, the delivery address, the date of purchase, and the price paid. If you do not wish to have this information revealed in this manner,
          do not purchase products from this Website.
        </span>{" "}
        <br /> <br />
        <h4>19. Governing Law, Arbitration, and Class Action/Jury Trial Waiver</h4>
        <span>
          These Terms of Service will be governed by and interpreted under the laws of the State of California, USA, without regard to its principles of
          conflict of laws. You agree that any cause of action you have that arises out of or relates to these Terms of Service or your use of the Website must
          be brought by you within six months after the cause of action accrues. Otherwise, any such action by you against Sooperstock is permanently barred.
          <br /> <br />
          Governing Law. You agree that: (i) the Website and Sooperstock Products and services shall be deemed solely based in California; and (ii) the Website
          and any services provided by Sooperstock shall be deemed passive services that do not give rise to personal jurisdiction over us, either specific or
          general, in jurisdictions other than California. These Terms shall be governed by the internal substantive laws of the State of California, without
          respect to its conflict of laws principles. The parties acknowledge that these Terms evidence a transaction involving interstate commerce.
          Notwithstanding the preceding sentences with respect to the substantive law, any arbitration conducted pursuant to the terms of these Terms shall be
          governed by the Federal Arbitration Act (9 U.S.C. §§ 1-16). The application of the United Nations Convention on Contracts for the International Sale
          of Goods is expressly excluded. You agree to submit to the personal jurisdiction of the federal and state courts located in Los Angeles County,
          California for any actions for which we retain the right to seek injunctive or other equitable relief in a court of competent jurisdiction to prevent
          the actual or threatened infringement, misappropriation or violation of a our copyrights, trademarks, trade secrets, patents, or other intellectual
          property or proprietary rights, as set forth in the Arbitration provision below, including any provisional relief required to prevent irreparable
          harm. You agree that Los Angeles County, California is the proper forum for any appeals of an arbitration award or for trial court proceedings in the
          event that the arbitration provision below is found to be unenforceable.
          <br /> <br />
          Arbitration. Read this section carefully because it requires the parties to arbitrate their disputes and limits the manner in which you can seek
          relief from Sooperstock. For any dispute with Sooperstock, you agree to first contact us via email at support@sooperstock.com and attempt to resolve
          the dispute with us informally. In the unlikely event that Sooperstock has not been able to resolve a dispute it has with you after sixty (60) days,
          we each agree to resolve any claim, dispute, or controversy (excluding any claims for injunctive or other equitable relief as provided below) arising
          out of or in connection with or relating to these Terms, or the breach or alleged breach thereof (collectively, “Claims”), by binding arbitration by
          JAMS, under the Optional Expedited Arbitration Procedures then in effect for JAMS, except as provided herein. JAMS may be contacted at
          www.jamsadr.com. The arbitration will be conducted in Los Angeles County, California, unless you and Sooperstock agree otherwise. If you are an
          individual using Sooperstock Products, services, or the Website for non-commercial purposes: (i) JAMS may require you to pay a fee for the initiation
          of your case, unless you apply for and successfully obtain a fee waiver from JAMS; (ii) the award rendered by the arbitrator may include your costs of
          arbitration, your reasonable attorney’s fees, and your reasonable costs for expert and other witnesses; and (iii) you may sue in a small claims court
          of competent jurisdiction without first engaging in arbitration, but this does not absolve you of your commitment to engage in the informal dispute
          resolution process. Any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction. Nothing in this
          Section shall be deemed as preventing Sooperstock from seeking injunctive or other equitable relief from the courts as necessary to prevent the actual
          or threatened infringement, misappropriation, or violation of our data security, Intellectual Property Rights or other proprietary rights.
          <br /> <br />
          Class Action/Jury Trial Waiver. With respect to all persons and entities, regardless of whether they have obtained or used the Website or Sooperstock
          Products or services for personal, commercial or other purposes, all claims must be brought in the parties’ individual capacity, and not as a
          plaintiff or class member in any purported class action, collective action, private attorney general action or other representative proceeding. This
          waiver applies to class arbitration, and, unless we agree otherwise, the arbitrator may not consolidate more than one person’s claims. You agree that,
          by entering into this agreement, you and Sooperstock are each waiving the right to a trial by jury or to participate in a class action, collective
          action, private attorney general action, or other representative proceeding of any kind.
        </span>{" "}
        <br /> <br />
        <h4>20. Release and Waiver of Claims; Assumption of Risk</h4>
        <span>
          YOU HEREBY AGREE TO THE FOLLOWING, WHICH IS PERMITTED BY LAW: (i) to waive any and all claims that you have or may have in the future against
          Sooperstock, or any of its distributors, resulting from use of the Website and Sooperstock Products and services; and (ii) to release Sooperstock or
          any of its distributors from any and all liability from any loss, damage, injury or expense that you or any users of this Website and any Sooperstock
          Products or services may suffer as a result of the use of any of the foregoing, due to any cause whatsoever, including negligence or breach of
          contract on the part of Sooperstock, in the design or manufacture of the Website or of any Sooperstock Products or services.
          <br /> <br />
          In the event of your death or incapacity, these Terms shall be effective and binding upon your heirs, next of kin, executors, administrators, assigns
          and representatives. You hereby expressly waive any and all rights and benefits conferred upon you by the provisions of Section 1542 of the Civil Code
          of California (or any applicable analogous law), which states:
          <br /> <br />
          A general release does not extend to claims which the creditor does not know or suspect to exist in his or her favor at the time of executing the
          release, which if known to him or her must have materially affected his or her settlement with the debtor.
          <br /> <br />
          You and Sooperstock understand and agree that claims or facts in addition to or different from those which are now known or believed by each of them
          to exist may hereafter be discovered, but it is your intention to release all claims you have or may have against Sooperstock and any and all of its
          successors, subsidiaries, parents, affiliates, investors, branches or related entities, or those entities’ officers, directors, employees,
          stockholders, partners, members, consultants, agents, attorneys, employee benefit plans or assigns, whether those claims are known or unknown,
          suspected or unsuspected.
        </span>{" "}
        <br /> <br />
        <h4>21. Trademarks and Copyright</h4>
        <span>
          This Website features trademarks, service marks, logos, text, software, files, graphics, photos, images, design, music, video and data that are the
          property of Sooperstock and its affiliates or licensors. This Website also may include trademarks, service marks, logos, text, software, files,
          graphics, photos, images, design, music, video and data of other third parties. All of these trademarks, service marks, logos, text, software, files,
          graphics, photos, images, design, music, video and data are the property of their respective owners, and you agree not to use them in any manner
          without the prior permission of the applicable owner. This Website and all of its content are protected under copyright, trademark and other laws of
          the United States and other countries.
        </span>{" "}
        <br /> <br />
        <h4>22. Use of Material from this Website</h4>
        <span>
          The content of this Website, including but not limited to, any text, software, files, graphics, photos, images, designs, music, musical compositions,
          video, audio visual works, and data found on this Website (collectively the “Materials”), are the property of and owned by Sooperstock or its
          licensors, and are protected by copyright, trademark, and/or other laws. You expressly agree that you are prohibited from, including but not limited
          to, the following: reproducing, copying, modifying, displaying, adapting, publishing, translating, performing publicly, reverse engineering,
          transferring, transmitting, broadcasting, distributing, licensing, selling, creating derivative works of, or gifting, in whole or in part, the
          Materials.
          <br /> <br />
          The Website and related content is provided for informational purposes only. Your use of this Website is at your sole risk. This Website is provided
          on an “as is” and on an “as available” basis. Although we make all reasonable efforts to ensure that the content of the Website is updated and
          corrected, we do not guarantee the accuracy of any content. The material contained on this Website may contain inaccuracies and typographical errors.
          You agree that we have no duty to screen content that is provided to the Website by you or others, nor are we liable for such content. We have the
          right, at our sole discretion, to refuse to post or to edit submitted content. We reserve the right to remove content for any reason, but we are not
          responsible for any failure or delay in removing such material. Changes are periodically made to the Website and may be made at any time period. If
          you download any materials from this Website, you do so at your own discretion and risk. You will be solely responsible for any damage to your
          computer system or data that results from the download of any such materials.
        </span>
        <br /> <br />
        <h4>23. Disclaimer of Warranties</h4>
        <span>
          Products and services, and this Website, are provided on an "as is" and "as available" basis. Use of the foregoing is at your own risk. To the maximum
          extent permitted by applicable law and to the extent not covered under the applicable product warranty, Sooperstock expressly disclaims any and all
          warranties of any kind, whether express or implied, whether related to use of this Website or Products or services, including, but not limited to,
          implied warranties of merchantability, fitness for a particular purpose, or non-infringement. No advice or information, whether oral or written,
          obtained by you from Sooperstock or through any Product or service will create any warranty not expressly stated herein. Without limiting the
          foregoing, Sooperstock, its subsidiaries, its affiliates, and its licensors do not warrant that the content or any information on this Website is or
          will be complete, accurate, adequate, reliable, useful, timely, or correct; that the Products and services, and this Website, will meet your
          requirements or be free from defects, including product or device issues; that this Website will be available at any particular time or location,
          uninterrupted or secure; that any defects or errors will be corrected; or that the Products or services, or this Website, is free of viruses or other
          harmful components. Any content downloaded or otherwise obtained through the use of this Website is downloaded at your own risk and you will be solely
          responsible for any damage to your computer system or mobile device or loss of data that results from such download or your use of this Website.
          <br /> <br />
          Sooperstock does not warrant, endorse, guarantee, or assume responsibility for any product or service advertised or offered by a third party through
          the service or any hyperlinked website or service, and Sooperstock will not be a party to or in any way monitor any transaction between you and
          third-party providers of products or services.
          <br /> <br />
          Federal law, some states, provinces and other jurisdictions do not allow the exclusion and limitations of certain implied warranties, so the above
          exclusions may not apply to you. These Terms give you specific legal rights, and you may also have other rights which vary from state to state. The
          disclaimers and exclusions under these Terms will not apply to the extent prohibited by applicable law.
        </span>
        <br /> <br />
        <h4>24. Limitation of Liability</h4>
        <span>
          To the fullest extent permitted under applicable law, you understand and agree that neither Sooperstock nor any of its affiliates, agents, directors,
          employees, suppliers, licensors, or third-party content providers shall be liable for any direct, indirect, incidental, special, exemplary,
          consequential, punitive or any other damages, including without limitation damages for loss of profits, goodwill, use, data or other intangible
          losses, relating to or resulting from: 1) purchase and/or use of any Products or services; 2) use of, access to, or inability to use this Website; 3)
          use or inability to use any other website you access from a link through this Website; or 4) any actions we take or fail to take as a result of email
          messages you send us; in each case, in an amount exceeding the amount you paid to Sooperstock hereunder or $100.00, whichever is greater. This
          limitation of liability includes, but is not limited to, damages for errors, omissions, interruptions, defects, delays, computer viruses, loss of
          profits, loss of data, unauthorized access to and alteration of transmissions and data and any other tangible or intangible losses. This limitation
          applies regardless of whether the damages are claimed under the terms of a contract, or as a result of negligence, or otherwise arise out of or in
          connection with the use, inability to use, or performance of the information, services, products or materials available from this Website, and even if
          we or our representatives have been negligent or if we or our representatives have been negligent or have been advised of the possibility of such
          damages. Because some jurisdictions do not allow limitation of liability for consequential or incidental damages, all or some of the above limitations
          may not apply to you.
        </span>
        <br /> <br />
        <h4>25. Miscellaneous</h4>
        <span>
          Severability: A finding that any term or provision of these Terms of Service is invalid or unenforceable will be removed from these Terms of Service
          and will not affect the validity or enforceability of the remaining Terms of Service.
          <br /> <br />
          Assignment: These Terms, and any rights and licenses granted hereunder, may not be transferred or assigned by you, but may be assigned by Sooperstock
          without restriction. Any attempted transfer or assignment in violation hereof shall be null and void.
          <br /> <br />
          No waiver by Sooperstock of any breach of this Agreement is effective unless in writing.
          <br /> <br />
          You agree that these Terms of Service, the Privacy Policy, and our Warranty, which are incorporated by reference, constitute the entire agreement
          between you and Sooperstock with respect to the Website and your relationship with Sooperstock and that there are no further understandings,
          agreements, or representations with respect to the Website that are not specified in these Terms of Service.
          <br /> <br />
          All notice required or permitted under these Terms of Service shall be made in writing by email to:
          <br /> <br />
          support@sooperstock.com
        </span>
        <h2 style={{ padding: "100px 0 20px 0" }}>
          <span class="c2 c3">Sooperstock Website Privacy Policy</span>
        </h2>
        <div class="c1 c4">
          <span class="c2 c0"></span>
        </div>
        <div class="c1">
          <span class="c0">
            You may know that there are laws about how businesses can collect and use information from their customers, app users, or anyone visiting their
            website. These laws set different requirements, but they all relate to the same idea, which is that you have the right to know what information
            you&#39;re sharing with us, what we&#39;re doing with it, and why. When the information that we collect identifies you or your household&mdash;or if
            it{" "}
          </span>
          <span class="c0 c7">could</span>
          <span class="c2 c0">
            &nbsp;identify you or your household&mdash;it&#39;s called &quot;personal data.&quot; We want to and are required to protect your personal data. Our
            Privacy Policy explains how we try to do so, but it&#39;s a long document with a lot to discuss. So, you can:
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">1. Go to the section that has the topic you&#39;re interested in;</span>
        </div>
        <div class="c1">
          <span class="c2 c0">2. Use a keyword search for your topic; or</span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            3. Get in touch with us to ask a question, ask us to show you the data we have about you, or ask us to delete your personal data.
          </span>
        </div>
        <br />
        <br />
        <h4>
          <span class="c2 c3">1. General Statements</span>
        </h4>
        <div class="c1">
          <span class="c2 c0">
            1.1 This is the Privacy Policy for Sooperstock, Inc., but we&rsquo;ll refer to ourselves as &ldquo;Sooperstock,&rdquo; or &ldquo;the Company,&rdquo;
            or use &ldquo;we/us/our&rdquo; pronouns.
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            1.2 We have a Privacy Policy for a few reasons. First, it&rsquo;s required by law. Second, and more importantly, we want you to understand how we
            use data so you can make an informed decision about how you share with us, what you share with us, and how we use your information. Finally, our
            Privacy Policy sets internal rules for how we use data and it holds us accountable: if we don&rsquo;t tell you what we&rsquo;re doing here, in the
            Privacy Policy, we won&rsquo;t do it at all unless we specifically obtain your permission.
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            1.3 We want this Privacy Policy to be understandable on its own, but there are concepts, terms, and phrases that have specialized meaning because
            they come directly from privacy laws. You can look at the &ldquo;Further Reading&rdquo; section to get a clearer idea of what these terms mean.
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            1.4 We operate in more than one geography and specific laws in many places require that specific things are included within a privacy policy. We
            believe that including all these requirements throughout a privacy policy makes it harder for normal people to read. As such, we made the decision
            to write this Privacy Policy in the clearest way that we can and included the specific international legal requirements in the &ldquo;International
            Rights&rdquo; section at the end of this Privacy Policy.
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            1.5 In some sections below we refer to &ldquo;GDPR art.&rdquo; and then mention some numbers and letters. Where we do this, we are referencing a
            specific article within the European Union&rsquo;s General Data Protection Regulation (or GDPR) that permits us to collect and use your data in a
            specific way. We do this for two reasons: (1) because we are required to under GDPR and (2) because GDPR is generally considered to be the one of
            the highest standards of privacy law in the world and we want you to know that, irrespective of where you live, we are applying the highest
            standards when it comes to your personal data.
          </span>
        </div>
        <br />
        <br />
        <h4>
          <span class="c2 c3">2. Information About Sooperstock and this Privacy Policy</span>
        </h4>
        <div class="c1">
          <span class="c2 c0">
            2.1 This Privacy Policy outlines how Sooperstock collects and processes your personal data through your use of Sooperstock&rsquo;s website, app, or
            any other services sponsored or controlled by Sooperstock (an in-person survey, for instance). In other words, if we&rsquo;re collecting personal
            data in any form, this Privacy Policy applies.
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            2.2 Along those lines, Sooperstock is the &ldquo;Controller&rdquo; of the personal data it collects, which means we are the entity that decides how
            to collect, process, and use the personal data.
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            2.3 We&rsquo;ll provide links to this Privacy Policy wherever we can &ndash; on our websites, in the app, on another website before you take a
            survey, etc. You should read this Privacy Policy, think about it, ask questions, and decide if you&rsquo;re comfortable with it. Also read our Terms
            and Conditions, which control how we provide our services, and any other notices or policies we post, so that you can make an informed decision
            about interacting with us.
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            2.4 When we make a change to this Privacy Policy, we&rsquo;ll post a notice for you to review. This Privacy Policy was last changed on October 01,
            2024.
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            2.5 We are not responsible, though, for links to third party sites that we present to you, either on this website or in the app. Once you access
            sites or apps via those links, our Privacy Policy no longer applies, and so you&rsquo;ll need to read their privacy policies as well.
          </span>
        </div>
        <br />
        <br />
        <h4>
          <span class="c2 c3">3. What Data Are We Collecting About You?</span>
        </h4>
        <div class="c1">
          <span class="c2 c0">
            3.1 Not all data is &ldquo;personal data&rdquo; under the law, but a lot of it is, and more than you might think. Because we operate in more than
            one country, we&rsquo;ve taken the approach that the broadest definition of personal data is best, because it allows us to explain what we collect
            more simply. And so, for Sooperstock&rsquo;s purposes, personal data is:
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            Any information that can, either alone or with other information, be used to identify an actual human person or their household.
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">3.2 These are the categories of personal data that we collect:</span>
        </div>
        <div class="c1">
          <span class="c0">&bull; </span>
          <span class="c3">&quot;Basic Data&quot;</span>
          <span class="c2 c0">
            &nbsp;means your name, your email address, your physical address, your phone number, your gender identification, and your verified age. Basic Data
            is collected only if you elect to provide it to Sooperstock in the course of using the Sooperstock website or Sooperstock app. You may be required
            to provide Basic Data to use some of Sooperstock&rsquo;s services, such as completing purchases from Sooperstock&rsquo;s website or entering
            promotions.
          </span>
        </div>
        <div class="c1">
          <span class="c0">&bull; </span>
          <span class="c3">&quot;Purchase and Warranty Data&quot;</span>
          <span class="c2 c0">
            &nbsp;is all Basic Data plus credit card or payment information, age verification information and any ID you&rsquo;ve used to verify your age, your
            warranty number, your Sooperstock device serial number, any claims or issues you&#39;ve reported to us related to your device or the warranty, and
            any other information related to your purchase of a device or service from us. Purchase and Warranty Data is collected only if you elect to provide
            it to Sooperstock in the course of using the Sooperstock website or customer support services. You may be required to provide Purchase and Warranty
            Data to use some of Sooperstock&rsquo;s services, such as completing purchases from Sooperstock&rsquo;s website, receiving customer support and
            making a warranty claim.
          </span>
        </div>
        <div class="c1">
          <span class="c0">&bull; </span>
          <span class="c3">&quot;Product Data&quot;</span>
          <span class="c2 c0">&nbsp;means your Sooperstock device&#39;s serial number or any Technical Data related to Sooperstock websites.</span>
        </div>
        <div class="c1">
          <span class="c0">&bull; </span>
          <span class="c3">&quot;Technical Data&quot;</span>
          <span class="c2 c0">
            &nbsp;means any information we collect as we operate our websites and apps, like your IP address when you connect to our websites, your mobile
            device identifier, what browser you used to access our site and what operating system you&#39;re using, the movement of your mouse on the screen
            (mouse hovers and clicks, for example) the length of time you spend on our website or app, any extensions or apps you pair with ours. We don&#39;t
            use your IP address to track or record your specific location, just the country and city where your login occurred. That said, it&#39;s possible to
            pair even a generalized IP address with other information to identify someone, and so we treat IP addresses like personal data. As Technical Data is
            part of Product Data, Technical Data is collected automatically if you use Sooperstock websites.
          </span>
        </div>
        <div class="c1">
          <span class="c0">&bull; </span>
          <span class="c3">&quot;Profile Data&quot;</span>
          <span class="c2 c0">
            &nbsp;means any detailed Sooperstock profile information that you&#39;ve set up and shared with us. Your profile data includes your account id, your
            password, your activity while logged in (including reviews, ratings, notes, submissions, comments, and feedback), social media posts, purchase
            activity and history, and stores where you&#39;ve purchased items and used your Sooperstock profile during the purchase. Profile Data is collected
            only if you elect to provide it to Sooperstock in the course of creating and using a Sooperstock account profile. You do not have to create or use a
            user account to have a great experience using a Sooperstock device.
          </span>
        </div>
        <div class="c1">
          <span class="c0">&bull; </span>
          <span class="c3">&quot;Feedback and Marketing Data&quot;</span>
          <span class="c2 c0">
            &nbsp;means information that we collect to suggest new products or services that you might find interesting. This includes any surveys or
            questionnaires we conduct (whether they&#39;re in an email, on our website, in the app, or at a physical location). Feedback and marketing data also
            means all other forms of personal data, your preferences when it comes to how, when, and why we communicate with you about our products and
            services, and any interactions you have with our marketing materials (for instance, whether you opened a survey or responded to an in-store
            questionnaire). Feedback and Marketing Data is collected whenever you provide the types of feedback described in the section or interact with our
            marketing materials. We talk a lot more about marketing in the section below called &quot;Marketing our Products.&quot;
          </span>
        </div>
        <div class="c1">
          <span class="c0">&bull; </span>
          <span class="c3">&ldquo;Third Party Data&rdquo;</span>
          <span class="c2 c0">
            &nbsp;means any personal data about you that we obtain &ndash; whether by purchasing it or simply receiving it &ndash; from anywhere outside of
            Sooperstock. We don&rsquo;t control how those third parties get their data about you, but we won&rsquo;t take any personal data about you from a
            third party unless they can prove to us that they had your data lawfully and properly in the first place and are permitted to share it with us.
            Oftentimes, but not always, this data is publicly available information like an address, business title, or social media profile.
          </span>
        </div>
        <div class="c1">
          <span class="c0">&bull;</span>
          <span class="c3">&nbsp;&ldquo;Commercial Data&rdquo; </span>
          <span class="c2 c0">
            means any personal data that we obtain about our partners, vendors, contractors, counterparties, or anyone that we do business with. This is not a
            category that includes data we collect about our customers, nor is it the data we collect about our employees (which is governed by our own internal
            privacy policy). Instead, this is the data we collect in the operation of our business, and includes any personal data that we collect and process
            in the course of dealing with non-customers in California.
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            3.3 As explained below, we may combine different kinds of personal data in the performance of our services or sale of products to you. We&rsquo;ll
            also sometimes combine the personal data you&rsquo;ve given us with non-personal data. For example, we might combine data about the time and
            location of your purchase with weather data to get an idea of whether rainy days change how customers decide to buy products. If the combined data
            can identify you, we&rsquo;ll treat it like personal information, even though some parts of the combined data (like the weather) can&rsquo;t
            identify you.
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            3.4 Unless you&rsquo;ve provided us with informed consent of your willingness to participate in a survey or study, we will not solicit any sensitive
            categories of personal data about you. This includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual
            orientation, political opinions, trade union membership, information about your health and genetic and biometric data, or information about criminal
            convictions or offenses.
          </span>
        </div>
        <br />
        <br />
        <h4>
          <span class="c2 c3">4. Age Verification</span>
        </h4>
        <div class="c1">
          <span class="c2 c0">
            4.1 The products that we sell on our website are highly regulated, and we age-restrict their sale. We are committed to preventing sales to minors
            and we take measures to mitigate this.
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            4.2 We utilize trusted verification services to verify the information that you provide and ensure that you qualify to access and purchase products
            from our website. This process has been developed with the customer&rsquo;s privacy in mind with detailed information neither shared nor accessible.
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            4.3 In some cases, we might not be able to verify your age and/or identity through our third-party verification service. If you are unable to verify
            your age with the requested information, you will be requested to upload a copy of your government ID that enables the verification service to
            manually verify your date of birth. We only have access to the result that they provide &ndash; we do not receive a file with your ID to be stored
            on our servers or database.
          </span>
        </div>
        <br />
        <br />
        <h4>
          <span class="c2 c3">5. How We Collect Personal Data</span>
        </h4>
        <div class="c1">
          <span class="c2 c0">We collect personal data in a variety of ways, depending on how you interact with us, including:</span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            5.1 Direct interactions. You may give us your Basic, Purchase and Warranty, Product, Usage, Profile, or Feedback and Marketing Data, by interacting
            with us, as when you:
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">&bull; purchase products on our website;</span>
        </div>
        <div class="c1">
          <span class="c2 c0">&bull; create an account or profile;</span>
        </div>
        <div class="c1">
          <span class="c2 c0">&bull; use a Sooperstock product;</span>
        </div>
        <div class="c1">
          <span class="c2 c0">&bull; sign up to receive information, including marketing information, from us;</span>
        </div>
        <div class="c1">
          <span class="c2 c0">&bull; make a claim based on your warranty or communicate with us about your device;</span>
        </div>
        <div class="c1">
          <span class="c2 c0">&bull; contact customer support or request technical assistance;</span>
        </div>
        <div class="c1">
          <span class="c2 c0">&bull; access Sooperstock via social media accounts or Sooperstock&rsquo;s website(s);</span>
        </div>
        <div class="c1">
          <span class="c2 c0">&bull; enter a promotion or survey;</span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            &bull; engage in a commercial transaction or relationship with us as a business entity, contractor, vendor, or other third party;
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">&bull; give us feedback or reviews; or</span>
        </div>
        <div class="c1">
          <span class="c2 c0">&bull; apply and/or interview for a job with us.</span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            5.2 Through automated technologies or interactions. As you interact with our website, we automatically collect Product Data about your equipment,
            browsing actions and patterns. We collect this personal data by using cookies, server logs and other similar technologies. We may also receive
            Product Data about you if you visit other websites employing our cookies. Please see our cookie policy for further details.
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            5.3 From third parties or publicly available sources. We may receive personal data about you from various third parties and public sources. That
            includes, among others, our third-party vendors for:
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">&bull; completing sales;</span>
        </div>
        <div class="c1">
          <span class="c2 c0">&bull; monitoring activity on our website, including user interaction and fraud prevention; and</span>
        </div>
        <div class="c1">
          <span class="c2 c0">&bull; verifying your age.</span>
        </div>
        <br />
        <br />
        <h4>
          <span class="c2 c3">6. Why (and How) We Use Personal Data</span>
        </h4>
        <div class="c1">
          <span class="c2 c0">
            6.1 As mentioned above, there are several lawful justifications for Sooperstock using your personal data in certain situations. Our promise to you
            is that we will only use personal data when we have a lawful justification for doing so. In some situations, the only lawful justification for using
            your personal data is when you provide us with your consent to use your personal data. If you ever give us your consent to use your personal data,
            don&rsquo;t worry, you are not giving that consent forever. We will always give you the option to change your mind and withdraw your consent at any
            time.
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">6.2 The following list sets out how we use personal data, and the lawful basis for doing so:</span>
        </div>
        <div class="c1">
          <span class="c0">&bull; </span>
          <span class="c3">Verifying your age.</span>
          <span class="c2 c0">
            &nbsp;We need to verify that you are of legal age to purchase our products, and so we collect Basic Data to do so. We need this information to be
            able to fulfill our part of our contract with you, and so collecting this data is necessary to the performance of our contract with you (GDPR art.
            6(1)(b)).
          </span>
        </div>
        <div class="c1">
          <span class="c0">&bull; </span>
          <span class="c3">Completing a transaction.</span>
          <span class="c2 c0">
            &nbsp;We need Basic Data and Purchase and Warranty Data so you can buy one of our products, pay for it, and for us to ship it to you. We need
            Commercial Data to operate our business and transact with others. We need this information to be able to fulfill our part of our contract with you,
            and so collecting this data is necessary to the performance of our contract with you (GDPR art. 6(1)(b)).
          </span>
        </div>
        <div class="c1">
          <span class="c0">&bull; </span>
          <span class="c3">Providing customer service.</span>
          <span class="c2 c0">
            &nbsp;Depending upon what you contact us for and request, we will use any and all categories of personal data we have in order to provide you with
            customer service. For instance, if you call us to discuss a problem with your shipment, we&rsquo;ll use Basic Data, Purchase and Warranty Data, and
            likely also Product to be able to respond to your query. We need this information to be able to fulfill our part of our contract with you (GDPR art.
            6(1)(b)), and because we have a legitimate interest in being able to respond to your questions (GDPR art. 6(1)(f)).
          </span>
        </div>
        <div class="c1">
          <span class="c0">&bull; </span>
          <span class="c3">Product safety, failure diagnosis and correction.</span>
          <span class="c2 c0">
            &nbsp;We want our products to operate in the best way possible for you. The more we know about the basic operation and workings of our products, the
            more quickly we can understand that there is a problem and fix that problem. More importantly if there was ever an issue that impacted the safety of
            Sooperstock users, we would want to discover it and take corrective steps ASAP. We therefore use Product Data to monitor the healthy working of
            Sooperstock devices and the Sooperstock app so that we can analyze trends in failures and bugs to establish whether these are isolated events or
            product issues that need solving. We use Product Data for this purpose because we have a legitimate interest in ensuring consumer safety and
            resolving product issues (GDPR art. 6(1)(f)).
          </span>
        </div>
        <div class="c1">
          <span class="c0">&bull; </span>
          <span class="c3">Marketing to you and others.</span>
          <span class="c2 c0">&nbsp;See section 7 below, &ldquo;Marketing our Products.&rdquo;</span>
        </div>
        <div class="c1">
          <span class="c0">&bull; </span>
          <span class="c3">Managing our website and apps.</span>
          <span class="c2 c0">
            &nbsp;We&rsquo;ll use Basic Data, Technical Data, Purchase and Warranty Data, and Profile Data to keep our website and app operating properly (fraud
            detection and prevention, site maintenance and updates, app maintenance and updates, IP logs). We use this data because we have a legitimate
            interest in administering/improving our site and apps, running IT services, ensuring network security, and preventing fraud (GDPR art. 6(1)(f)), and
            because we need to demonstrate our compliance with data security obligations both as a legal matter and if we are involved in a business
            reorganization (a merger or acquisition) (GDPR art. 6(1)&copy;, GDPR art. 6(1)(f)).
          </span>
        </div>
        <div class="c1">
          <span class="c0">&bull; </span>
          <span class="c3">Creating insights and analysis.</span>
          <span class="c2 c0">
            &nbsp;We&rsquo;ll use Basic Data, Product Data, Usage Data, Profile Data, and Feedback and Marketing Data to analyze how customers use products, how
            they use, review or rate other products and services related to ours, how we might be able to build better products and to understand general trends
            in the market. We may share or sell those analyses or data points to third parties, but we want to be clear: we&rsquo;re not selling your name,
            address, personal usage, or anything that directly identifies you. Instead, we&rsquo;re going to compile a picture that says something like
            &ldquo;customers in Colorado age 40-50 like these products,&rdquo; or &ldquo;people who identified themselves as professionals use pods two times
            more often on Mondays than any other day of the week.&rdquo; It will never be &ldquo;Customer A, who is a bank teller, used X product on Y day for Z
            length of time.&rdquo;
          </span>
        </div>
        <div class="c1">
          <span class="c0">&bull; </span>
          <span class="c3">Internally managing our company and engaging with third parties. </span>
          <span class="c2 c0">
            We use Commercial Data in the course of operating our business, just as any company would do. We don&rsquo;t use this data for any purposes other
            than those for which it was originally given (for instance, we don&rsquo;t use Commercial Data to market our products to an independent contractor
            who performed a task for the Company). To the extent that a contract, agreement, or other document sets out uses for Commercial Data in a manner
            that is different to what is set out here, that document will control.
          </span>
        </div>
        <div class="c1">
          <span class="c0">&bull; </span>
          <span class="c3">Creating and managing your profile.</span>
          <span class="c2 c0">
            &nbsp;When you create a profile on our website or in our app, you agree to share Basic Data, Product Data, Usage Data and Profile Data with us so
            that we can provide you with a tailored, custom experience (use metrics, recommendations, trends, etc). We need this information to be able to
            fulfill our part of our contract with you, and so collecting this data is necessary to the performance of our contract with you (GDPR art. 6(1)(b)).
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            We also use this information to create our own internal user profile for you, which we use to market products to you, deliver content that we think
            is relevant to you, to advertise to you, to learn about you in particular and our customers more generally, and to create an analysis of our
            business, our customers, and our market. We have a legitimate interest in doing these things to grow our business and learn about our industry (GDPR
            art. 6(1)(f)), but we will only process this information in this way if you have agreed (consented) to us doing so, and you can withdraw your
            consent at any time (GDPR art. 6(1)(a)).
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            6.3 We will only keep your personal data for as long as necessary under the circumstances in which we collected it, including our obligation to hold
            onto it for legal, regulatory, or accounting purposes. If we are able to make data completely anonymous (that is, it can&rsquo;t be used to identify
            you), we may keep that data indefinitely for statistical or analytic purposes.
          </span>
        </div>
        <br />
        <br />
        <h4>
          <span class="c2 c3">7. Marketing our Products</span>
        </h4>
        <div class="c1">
          <span class="c2 c0">
            A general note on marketing data: We advertise because we want people to buy, and like, our products, and because we want our business to succeed.
            That means we place ads, send emails, run promotions, send out questionnaires, take surveys, conduct interviews, and do everything else that a
            marketing department does to try to create a brand. We&rsquo;re telling you this bluntly so that you have an easier time understanding what we mean
            when we talk about marketing: it&rsquo;s our effort to help our business grow.
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            Part of that growth is understanding what our existing customers like, what they don&rsquo;t like, and what they might like in the future. We want
            to know why our users bought a device, or a particular pod. We think that knowing what our customers like will help us improve our existing products
            and services and design and deliver new, better ones in the future.
          </span>
        </div>
        <div class="c1">
          <span class="c0">
            The most important part in all of this: you have absolute control over how, and if, we market to you. The basis upon which we use this information
            is your consent (GDPR art. 6(1)(a)) and you can withdraw that consent at any time. Want to share details of your usage so that we can send you
            promotional information that matches your interests? Great. Don&rsquo;t want us to know anything about your Usage Data at all and never email you?
            Sure&mdash;although we may have to email you if we have a legal obligation to do so or, for example, if you contact us. The point is that you can{" "}
          </span>
          <span class="c0 c7">always</span>
          <span class="c2 c0">&nbsp;decide how much information you share and how we contact you when it comes to marketing.</span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            7.1 Promotional offers
            <br />
            We use your Basic, Product, Usage, Profile, Feedback and Marketing Data, and occasionally Third Party Data to create a marketing profile for you so
            that we can send you information about what devices, products, services, or other goods you may find interesting. We&rsquo;ll only send you emails
            or texts if you&rsquo;ve opted-in to receive marketing communications. We may ask you to opt-in to marketing communications via email, on our
            website, in the app, or in a physical location like a store or a festival.
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            7.2 Third-party marketing
            <br />
            We may use third-party vendors to help us identify who our customers are when they visit our site, help us reach out to customers that have opted in
            to receive marketing communications, or to find more information about customers that have opted in to receive marketing communications. We share
            data with them so that it&#39;s easier for us to track what our customers are doing on our website -- figuring out what they&#39;re browsing
            through, what images they click on, what their activity is like on the site if they&#39;ve forgotten to log into their account, etc. This is about
            us getting a clearer idea of what our customers do online, but only on Sooperstock sites. And we use this information only for internal marketing
            purposes: we won&#39;t sell any of the insights or data we get about our customers to anyone else without your consent. Some third-party vendors may
            also have access to this data, and we won&#39;t control what they do with it (though they still can only use it for lawful purposes). We may also
            use third-party vendors to help us reach out to individuals who may be interested in our products via direct mail.
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            7.3 Opting out
            <br />
            You can ask us or third parties to stop sending you marketing messages at any time by submitting a Privacy Request.
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            Where you opt-out of receiving these marketing messages, this will not apply to personal data provided to us as a result of a product/service
            purchase, warranty registration, product/service experience or other transactions.
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            7.4 Cookies
            <br />
            Cookies are small files that track your activity online. Some of them are purely functional (they allow websites to load faster) and some of them
            are for marketing (tracking how you interact with websites). We use cookies for both purposes, but you can read more about cookies (and how to block
            them) in our cookie policy.
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            7.5 Change of purpose
            <br />
            We only use your personal data in the ways we&rsquo;ve outlined in this Privacy Policy, unless we think there is a reason that we can use it for
            another purpose that 1) is fair, and 2) is compatible with the original reason we collected it. We&rsquo;ll tell you if we need to use your personal
            data for a purpose other than the original purpose for which we collected it.
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so.
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            7.6 Data retention
            <br />
            We delete or anonymize your personal data as soon as it is no longer required for the purposes we have collected, unless we are legally required to
            continue processing of your personal data. The one primary exception here is that, if you ask us to delete your data and &ldquo;forget&rdquo; you,
            or ask us not to contact you, we&rsquo;ll keep your email address on our master do-not-contact list as proof that we followed you request and so
            that we can avoid contacting you in the future.
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            For more information on the different categories of personal data and their retention periods, please contact us at support@sooperstock.com.
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            7.7 Automated Decisions
            <br />
            We don&rsquo;t use an automated decision-making system (an algorithm or machine learning tool) to make decisions about you. We&rsquo;ll use a system
            that makes recommendations for what we think you&rsquo;ll like, but acting on those recommendations is always in your hands, not ours.
          </span>
        </div>
        <br />
        <br />
        <h4>
          <span class="c2 c3">8. Disclosures of your personal data</span>
        </h4>
        <div class="c1">
          <span class="c2 c0">8.1 Sometimes, we will share your personal data with:</span>
        </div>
        <div class="c1">
          <span class="c0">&bull; </span>
          <span class="c3">Outside third parties.</span>
          <span class="c2 c0">
            &nbsp;As explained above, we use outside vendors and service providers to enable our company to function. The kinds of third parties we share your
            data with are:
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            &#9702; Service providers acting as processors based outside of the European Economic Area (EEA) who provide IT and system administration services
            including cookies/user experience/analytics.
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            &#9702; Professional advisers acting as processors including lawyers, bankers, auditors and insurers based outside the EEA who provide consultancy,
            banking, legal, insurance and accounting services.
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            &#9702; Logistics providers to process and deliver your order and to deal with any post-purchase and/or customer service issues.
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">&#9702; Customer support personnel who respond to questions and warranty claims.</span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            &#9702; Marketing companies to help us reach out to customers who have opted-in to receive marketing communications or new customers via direct
            mail.
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">We&rsquo;ll also share personal data if we buy, sell, transfer, or merge parts of our business with another company.</span>
        </div>
        <div class="c1">
          <span class="c0">&bull; </span>
          <span class="c3">Regulators.</span>
          <span class="c2 c0">
            &nbsp;If we are subject to an audit, review, reporting requirements or other inquiry by a properly constituted regulatory agency (like the Food and
            Drug Administration, for instance), they may require us to share the data we have, including personal data.
          </span>
        </div>
        <div class="c1">
          <span class="c0">&bull; </span>
          <span class="c3">Subpoenas and legal demands.</span>
          <span class="c0">
            &nbsp;We have to comply with lawful subpoenas or investigative demands from courts and law enforcement agencies. We want to be really transparent on
            this point:{" "}
          </span>
          <span class="c3">
            if law enforcement (or anyone else with a valid subpoena) follows the correct legal process and demands information about you from Sooperstock, we
            will take every measure to maintain your privacy but it&rsquo;s possible that we have to share that information.
          </span>
          <span class="c2 c0">&nbsp;</span>
        </div>
        <div class="c1">
          <span class="c0">8.2 We share your personal data outside third parties </span>
          <span class="c0 c7">only</span>
          <span class="c2 c0">
            &nbsp;to enable us to fulfill our part of our contract with you (GDPR art. 6(1)(b)), because you have consented to it (GDPR art. 6(1)(a)), or
            because it&rsquo;s necessary for a legal or regulatory requirement (GDPR art. 6(1)(c)). None of these third parties are allowed to use your personal
            data in any way that is different from the reasons we outline here.
          </span>
        </div>
        <br />
        <br />
        <h4>
          <span class="c2 c3">9. International transfers</span>
        </h4>
        <div class="c1">
          <span class="c2 c0">
            9.1 We are based in the United States and will transfer data from other parts of the world as outlined in this Privacy Policy.
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            9.2 For those present in the EU, if we transfer your personal data outside of the EEA to a place that does not have a similar degree of protection
            for personal data (as described under GDPR), we will use other measures to protect your data such as Standard Contractual Clauses (SCC).
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            9.3 If you have questions about transferring data out of the EEA, please contact us and we&rsquo;ll provide you with more information.
          </span>
        </div>
        <br />
        <br />
        <h4>
          <span class="c2 c3">10. Data security</span>
        </h4>
        <div class="c1">
          <span class="c2 c0">
            10.1 We work hard to keep your data (and ours) safe. We use a variety of tools &ndash; technological, administrative, and physical &ndash; to keep
            data secure. These safeguards are designed to ensure that whatever personal data we keep is protected against unlawful access or use. Despite our
            best efforts, however, no security measures are completely impenetrable.
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            10.2 We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach
            where we are legally required to do so.
          </span>
        </div>
        <br />
        <br />
        <h4>
          <span class="c2 c3">11. Your legal rights</span>
        </h4>
        <div class="c1">
          <span class="c2 c0">
            11.1 When you provide us with personal data, you have rights about how we use it, and why. In some circumstances, those rights are set out in
            specific legislation like the European Union&rsquo;s GDPR, Canada&rsquo;s PIPEDA, or California&rsquo;s Consumer Privacy Act. In general, you have
            the right to:
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            &bull; Request access to your personal data.
            <br />
            &bull; Request correction of your personal data.
            <br />
            &bull; Request erasure of your personal data.
            <br />
            &bull; Object to processing of your personal data.
            <br />
            &bull; Request restriction of processing your personal data.
            <br />
            &bull; Request transfer of your personal data.
            <br />
            &bull; Withdraw consent.
          </span>
        </div>
        <div class="c1">
          <span class="c0">If you wish to exercise any of the rights set out above, please submit a request by clicking </span>
          <span class="c6 c0">
            <a
              class="c5"
              href="https://www.google.com/url?q=https://privacy.pax.com/privacy-request&amp;sa=D&amp;source=editors&amp;ust=1728572453702087&amp;usg=AOvVaw3MPW8P3yMYdaRHHfV86ps_"
            >
              Privacy Request
            </a>
          </span>
          <span class="c2 c0">&nbsp;above.</span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            11.2 No fee usually required
            <br />
            In some rare circumstances, you may have to pay a fee regarding a request, but in general you don&rsquo;t have to pay anything to exercise these
            data rights.
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            11.3 What we may need from you
            <br />
            In order to make sure that you&rsquo;re the person entitled to exercise the rights listed above, we&rsquo;ll sometimes request information to verify
            your identity. We will not ask for more data than is necessary to confirm your identity.
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            11.4 Time limit to respond
            <br />
            We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly
            complex or you have made a number of requests. In this case, we will notify you and keep you updated.
          </span>
        </div>
        <br />
        <br />
        <h4>
          <span class="c2 c3">12. Third Party Services</span>
        </h4>
        <div class="c1">
          <span class="c2 c0">
            As explained above, we may provide links to websites or services operated by third parties. This Privacy Policy does not apply to these third-party
            websites or services. If you follow a link to any of these websites or services, please note that these websites or services have their own privacy
            policies and terms &amp; conditions, and that we do not accept any responsibility or liability for their policies.
          </span>
        </div>
        <br />
        <br />
        <h4>
          <span class="c2 c3">13. Contact Us</span>
        </h4>
        <div class="c1">
          <span class="c2 c0">If you have any questions about this Privacy Policy, please contact us:</span>
        </div>
        <div class="c1">
          <span class="c2 c0">By email: support@sooperstock.com</span>
        </div>
        <br />
        <br />
        <h4>
          <span class="c2 c3">14. Further Reading</span>
        </h4>
        <div class="c1">
          <span class="c2 c0">
            Privacy rights are very complicated. We want you to be able to make informed choices about how and why you share your data with us. Here are some
            links to important guidance and documents from governments and policy groups that talk about key issues. We&rsquo;ve outlined key rights under the
            GDPR and CCPA below, but here are some other helpful links:
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">Key Terms</span>
        </div>
        <div class="c1">
          <span class="c0">The European Commission provides a </span>
          <span class="c6 c0">
            <a
              class="c5"
              href="https://www.google.com/url?q=https://ec.europa.eu/info/law/law-topic/data-protection/reform/what-personal-data_en&amp;sa=D&amp;source=editors&amp;ust=1728572453703177&amp;usg=AOvVaw1LLvOt2nHlFcWjejmCDTz6"
            >
              good explanation of what &ldquo;personal data&rdquo;
            </a>
          </span>
          <span class="c0">&nbsp;is, and you can read the entire GDPR </span>
          <span class="c6 c0">
            <a
              class="c5"
              href="https://www.google.com/url?q=https://eur-lex.europa.eu/legal-content/EN/TXT/?qid%3D1532348683434%26uri%3DCELEX:02016R0679-20160504&amp;sa=D&amp;source=editors&amp;ust=1728572453703380&amp;usg=AOvVaw24Tvz6Y2KeGfGcqq0u5Ksr"
            >
              here
            </a>
          </span>
          <span class="c0 c2">.</span>
        </div>
        <div class="c1">
          <span class="c2 c0">Your EU Rights</span>
        </div>
        <div class="c1">
          <span class="c0">If you&#39;re present in the European Union, </span>
          <span class="c6 c0">
            <a
              class="c5"
              href="https://www.google.com/url?q=https://ico.org.uk/global/privacy-notice/your-data-protection-rights/&amp;sa=D&amp;source=editors&amp;ust=1728572453703659&amp;usg=AOvVaw1PSj8Gh-IdAqggBV0SlyPV"
            >
              the Information Commissioner&rsquo;s Office in the UK provides a succinct explanation the rights you have when it comes to data
            </a>
          </span>
          <span class="c2 c0">.</span>
        </div>
        <div class="c1">
          <span class="c2 c0">FTC Principles</span>
        </div>
        <div class="c1">
          <span class="c0">
            The Federal Trade Commission is the main US federal agency that handles privacy issues. They have a series of posts about consumer privacy rights{" "}
          </span>
          <span class="c6 c0">
            <a
              class="c5"
              href="https://www.google.com/url?q=https://www.consumer.ftc.gov/topics/privacy-identity-online-security&amp;sa=D&amp;source=editors&amp;ust=1728572453703919&amp;usg=AOvVaw2H7lYBv-zxqztcfxb5QC8c"
            >
              that you can read here
            </a>
          </span>
          <span class="c2 c0">.</span>
        </div>
        <div class="c1">
          <span class="c2 c0">PIPEDA</span>
        </div>
        <div class="c1">
          <span class="c0">
            Canada&rsquo;s Personal Information Protection and Electronic Documents Act (&ldquo;PIPEDA&rdquo;) covers privacy rights as well, and the Office of
            the Privacy Commissioner offers{" "}
          </span>
          <span class="c6 c0">
            <a
              class="c5"
              href="https://www.google.com/url?q=https://www.priv.gc.ca/en/privacy-topics/privacy-laws-in-canada/the-personal-information-protection-and-electronic-documents-act-pipeda/pipeda_brief/&amp;sa=D&amp;source=editors&amp;ust=1728572453704257&amp;usg=AOvVaw1zG2vnMfWyceuWnI83NC4a"
            >
              its explanation of rights here
            </a>
          </span>
          <span class="c2 c0">.</span>
        </div>
        <br />
        <br />
        <h4>
          <span class="c2 c3">15. Your California Privacy Rights</span>
        </h4>
        <div class="c1">
          <span class="c0">
            If you are a California customer, you have the right to receive, once per year, free of charge, 1) the identity of any third party company to which
            we have disclosed your personal information as defined by California&rsquo;s &ldquo;Shine the Light&rdquo; law for that company&rsquo;s own direct
            marketing purpose; and 2) a description of the categories of personal information disclosed. To request this information, submit a request by
            clicking{" "}
          </span>
          <span class="c0 c6">
            <a
              class="c5"
              href="https://www.google.com/url?q=https://privacy.pax.com/privacy-request&amp;sa=D&amp;source=editors&amp;ust=1728572453704701&amp;usg=AOvVaw3GoF5dvWFneK8mBf_FSse_"
            >
              Privacy Request
            </a>
          </span>
          <span class="c2 c0">
            &nbsp;above, or the mail address set forth in the section entitled &ldquo;Contact Us&rdquo; below. Requests must include &ldquo;California Privacy
            Rights Request&rdquo; in the first line of the description and include your name, street address, city, state, and ZIP code. Please note that we are
            not required to respond to requests made by means other than through the provided email or mail address.
          </span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            California consumers have a right to knowledge, access, and deletion of their personal information under the California Consumer Privacy Act.
            California consumers also have a right to opt out of the sale of their personal information by a business and a right not to be discriminated
            against for exercising their California privacy rights. Sooperstock does not discriminate in response to privacy rights requests.
          </span>
        </div>
        <div class="c1">
          <span class="c0">
            California consumers with a Sooperstock account or who interact with Sooperstock products can exercise their rights directly or through an
            authorized agent by signing in to their Sooperstock account. If you are a California consumer without a Sooperstock account and you or your
            authorized agent would like to exercise your privacy rights, to you can make a CCPA &ldquo;Do Not Sell&rdquo; request to us by submitting a request
            by clicking{" "}
          </span>
          <span class="c6 c0">
            <a
              class="c5"
              href="https://www.google.com/url?q=https://privacy.pax.com/privacy-request&amp;sa=D&amp;source=editors&amp;ust=1728572453705079&amp;usg=AOvVaw1TXNl-TNJb_W78H2GPjeSC"
            >
              Privacy Request
            </a>
          </span>
          <span class="c2 c0">&nbsp;above.</span>
        </div>
        <div class="c1">
          <span class="c2 c0">
            If you do not have a Sooperstock account, Sooperstock will ask you for information that we consider necessary to verify your identity for security
            and to prevent fraud. This information may include name, contact information, and information related to your transaction or relationship with
            Sooperstock, but the specific information requested may differ depending on the circumstances of your request for your security and to protect
            privacy rights. If we delete your personal information, we will both render certain personal information about you permanently unrecoverable and
            also deidentify certain personal information.
          </span>
        </div>
        <div class="c1">
          <span class="c2 c3">Do Not Track</span>
        </div>
        <div class="c1">
          <span class="c0">
            California law requires us to let you know whether we respond to web browser Do Not Track (DNT) signals. DNT is a way for users to inform websites
            that they do not want their webpage visits tracked. Since the industry and legal standard for what DNT means or how to comply with it are not
            conclusive, we currently do not respond to DNT signals. Learn more about DNT{" "}
          </span>
          <span class="c6 c0">
            <a
              class="c5"
              href="https://www.google.com/url?q=https://allaboutdnt.com/&amp;sa=D&amp;source=editors&amp;ust=1728572453705486&amp;usg=AOvVaw0bDZFNqzcFKgHKkU3BpOuw"
            >
              here.
            </a>
          </span>
        </div>
        <br />
        <br />
        <h4>
          <span class="c2 c3">16. Modifications</span>
        </h4>
        <div class="c1">
          <span class="c0">Sooperstock</span>
          <span class="c2 c0">&nbsp;may update this Privacy Policy at any time by publishing an updated version here.</span>
        </div>
        <br />
        <br />
        <h4>
          <span class="c2 c3">17. Change Log</span>
        </h4>
        <div class="c1">
          <span class="c0">October 1, 2024: Published Privacy Policy.</span>
        </div>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
};

export default Legal;
