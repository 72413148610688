// src/elements/HeaderShop.js

import React, { useEffect, useState } from "react";
import "../styles/global.css";
import { useNavigate } from "react-router-dom";
import { MenuDrawer } from "../screens/_menuDrawer";
import SearchFilters from "./SearchFilters";
import { useSearch } from "../context/SearchContext"; // Import context
import { getAuth } from "firebase/auth"; // Import Firebase Auth
import countCartItems from "../functions/countCartItems"; // Import countCartItems function
import { useFirestore } from "../firebase/FirestoreContext"; // Import Firestore context

const HeaderShop = ({ stickyHeader }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [localSearchTerm, setLocalSearchTerm] = useState("");
  const [isSearchEnabled, setSearchEnabled] = useState(false);
  const [cartCount, setCartCount] = useState(0); // State for cart item count
  const navigate = useNavigate();
  const auth = getAuth();
  const user = auth.currentUser;
  const firestore = useFirestore(); // Get Firestore instance

  const searchContext = useSearch(); // Use search context
  const { setSearchTerm, searchProducts, storeSearchTerm, getHistoryTerms, toggleRecentSearches, showRecentSearches } = searchContext || {}; // Destructure with fallback

  useEffect(() => {
    window.feather.replace();
  }, [isMenuOpen]);

  useEffect(() => {
    setSearchEnabled(localSearchTerm.length > 3);
  }, [localSearchTerm]);

  useEffect(() => {
    let unsubscribe; // Variable to hold the unsubscribe function

    if (user) {
      // Subscribe to cart item count updates if user is logged in
      unsubscribe = countCartItems(firestore, user.uid, setCartCount);
    } else {
      setCartCount(0); // Set count to 0 if no user is logged in
    }

    // Cleanup listener when the component is unmounted
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [firestore, user]);

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const handleSearchInputChange = (e) => {
    const value = e.target.value;
    setLocalSearchTerm(value);
    setSearchTerm(value); // Update search term in context
  };

  const handleSearchClick = async () => {
    if (isSearchEnabled) {
      await storeSearchTerm(localSearchTerm); // Store the search term in the search history
      searchProducts(localSearchTerm); // Perform the search to update search results
      toggleRecentSearches(false); // Hide recent searches when performing a new search
    }
  };

  const handleFocus = async () => {
    await getHistoryTerms(); // Fetch recent searches when input is focused
    toggleRecentSearches(true); // Show recent searches when input is focused
  };

  return (
    <>
      <header className="header-shop" style={styles.headerShop(stickyHeader)}>
        <div style={styles.header.container}>
          <div style={styles.menu.container} onClick={toggleMenu}>
            <i data-feather={isMenuOpen ? "x" : "menu"} style={{ width: "16px", height: "16px" }} />
          </div>
          <div style={styles.logo.container} onClick={() => navigate("/")}>
            {/*
            <span className="logo" style={styles.logo.text}>
              SOOPERSTOCK
            </span>*/}
            <img src="/images/sooperstock_logo.svg" alt="Sooperstock" style={{ height: "35px" }} />
          </div>
          <div style={styles.cart.container} onClick={() => navigate("/cart")}>
            <div style={{ width: "16px", height: "16px", position: "relative" }}>
              <i data-feather={"shopping-bag"} style={{ width: "16px", height: "16px" }} />
              <div style={styles.cartCount}>{cartCount}</div> {/* Display current number of items in cart */}
            </div>
          </div>
        </div>
      </header>

      <MenuDrawer isOpen={isMenuOpen} toggleMenu={toggleMenu} />
    </>
  );
};

// Styles for HeaderShop component
const styles = {
  headerShop: (stickyHeader) => ({
    position: stickyHeader ? "sticky" : "relative", // Conditional sticky styling
    top: stickyHeader ? 0 : "auto",
    zIndex: 1000,
    backgroundColor: "#fff",
  }),
  header: {
    container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "20px 0",
      borderBottom: ".2px solid #808080",
      backgroundColor: "#fff",
    },
  },
  menu: {
    container: {
      padding: "0 20px",
      cursor: "pointer",
    },
  },
  cart: {
    container: {
      padding: "0 20px",
      cursor: "pointer",
    },
  },
  cartCount: {
    position: "absolute",
    top: "-9px",
    right: "-13px",
    backgroundColor: "black",
    borderRadius: "50%",
    color: "white",
    fontSize: "10px",
    height: "18px",
    width: "18px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    fontWeight: 600,
  },
  logo: {
    container: {
      cursor: "pointer",
    },
    text: {
      fontSize: "18px",
    },
  },
};

export default HeaderShop;
