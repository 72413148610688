import React, { useEffect, useRef, useState } from "react";
import config from "../config";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useFirestore } from "../firebase/FirestoreContext";
import { doc, updateDoc, deleteDoc } from "firebase/firestore";

const SquarePaymentForm = ({ orderAmount, onSuccess, onFailure, totalAmount, address }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const db = useFirestore();

  const { cartID, items, subtotal, shippingCostsubtotal, cart_sooperstock_ID } = location.state || {};

  const paymentFormRef = useRef(null);
  const initializedRef = useRef(false);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [cardInstance, setCardInstance] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [addressFilled, setAddressFilled] = useState(address);
  const [paymentProcessing, setPaymentProcessing] = useState(false);

  useEffect(() => {
    if (!window.Square) {
      console.error("Square SDK not loaded");
      return;
    }

    const payments = window.Square.payments("sandbox-sq0idb-SoBz7295GsoqUiKfc98eJA", "LDE9GXWT6GHE0");

    async function initializePayment() {
      if (initializedRef.current) return;
      initializedRef.current = true;

      try {
        const card = await payments.card();
        await card.attach(paymentFormRef.current);
        setCardInstance(card);
        console.log("Card instance initialized");

        // Correctly add event listener for card input changes
        card.addEventListener("change", (event) => {
          const status = event.detail.status;
          if (status === "VALID") {
            setCardComplete(true);
          } else {
            setCardComplete(false);
          }
        });
      } catch (error) {
        console.error("Error initializing Square payments:", error);
      }
    }

    initializePayment();

    return () => {
      if (cardInstance) {
        const submitButton = document.querySelector("#card-button");
        submitButton?.removeEventListener("click", handlePaymentClick);
      }
    };
  }, []);

  useEffect(() => {
    setAddressFilled(address);
  }, [address]);

  const handlePaymentClick = async (event) => {
    event.preventDefault();
    setPaymentStatus("Processing payment...");
    setPaymentProcessing(true);

    // add address to cart document
    const docRef = doc(db, "Carts/CartsList/DataBase", cartID);
    await updateDoc(docRef, {
      shipmentAddress: address,
    });

    if (!cardInstance) {
      console.error("Card instance not available");
      setPaymentStatus("Card instance not available");
      return;
    }

    try {
      const result = await cardInstance.tokenize();
      if (result.status === "OK") {
        handlePaymentSuccess(result.token, 100);
        setPaymentProcessing(false);
      } else {
        console.error("Tokenization failed:", result.errors);
        setPaymentStatus("Payment failed: " + result.errors[0].message);
        handlePaymentFailure(result.errors);
        setPaymentProcessing(false);
      }
    } catch (error) {
      console.error("Error during payment processing:", error);
      setPaymentStatus("Failed to process payment: " + error.message);
      setPaymentProcessing(false);
    }
  };

  const handlePaymentSuccess = async (token, orderAmount) => {
    try {
      const response = await axios.post(`${config.apiConfig.API_ENDPOINT_URL}/payment/squarepay`, {
        sourceId: token,
        amount: orderAmount,
        cart_id: cartID,
      });

      if (response.status === 200) {
        console.log("Payment successful!");
        setPaymentStatus("Payment Successful!");
        onSuccess();
        //
        navigate("/payment-success", {
          state: {
            items: items,
            cart_sooperstock_ID: cart_sooperstock_ID,
          },
        });
      } else {
        console.error("Payment failed:", response);
        setPaymentStatus("Payment failed: " + response.data.message);
        onFailure();
      }
    } catch (error) {
      console.error("Error during payment processing:", error.response ? error.response.data : error.message);
      setPaymentStatus("Payment failed");
      onFailure();
    }
  };

  const handlePaymentFailure = (errors) => {
    setPaymentStatus("Payment failed");
    console.error("Payment failed:", errors);
    onFailure();
  };

  return (
    <div>
      <div id="card-container" ref={paymentFormRef}></div>

      <div style={styles.summarySection}>
        <div style={styles.summaryRow}>
          <span>Items:</span>
          <span>${subtotal.toFixed(2)}</span>
        </div>
        <div style={styles.summaryRow}>
          <span>Shipping:</span>
          <span style={styles.greenText}>${shippingCostsubtotal ? shippingCostsubtotal.toFixed(2) : "0.00"}</span>
        </div>
        <div style={styles.subtotalRow}>
          <strong>Subtotal:</strong>
          <strong>${totalAmount}</strong>
        </div>
      </div>

      <button
        onClick={handlePaymentClick}
        style={{
          ...styles.purchaseButton,
          backgroundColor: addressFilled ? "black" : "gray",
          cursor: addressFilled ? "pointer" : "not-allowed",
          display: "flex",
          justifyContent: "center",
        }}
        disabled={!addressFilled || paymentProcessing}
      >
        Purchase
        {paymentProcessing && (
          <dotlottie-player
            src="https://lottie.host/3e059437-1ab5-4a4f-bcc4-d95cdbb4f4b8/LEMNYtfxC9.json"
            background="transparent"
            speed="1"
            loop
            autoplay
            style={{ height: "20px", width: "20px", marginLeft: "10px" }}
          ></dotlottie-player>
        )}
      </button>

      <p style={styles.disclaimer}>Sooperstock is not responsible for lost or stolen packages during delivery</p>

      <p>{paymentStatus}</p>
    </div>
  );
};

const styles = {
  purchaseButton: {
    width: "100%",
    padding: "15px",
    backgroundColor: "gray",
    color: "white",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
    marginTop: "20px",
  },
  disclaimer: {
    fontSize: "12px",
    color: "gray",
    textAlign: "center",
    marginTop: "10px",
  },
  summarySection: {
    borderTop: "1px solid #ddd",
    paddingTop: "20px",
  },
  summaryRow: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
    fontSize: "14px",
  },
  subtotalRow: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
    fontSize: "16px",
    fontWeight: "bold",
  },
  greenText: {
    color: "green",
  },
};

export default SquarePaymentForm;
