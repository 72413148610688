import React, { useState, useEffect, useContext } from "react";
import { collection, onSnapshot, getDocs, doc, getDoc, setDoc, deleteDoc } from "firebase/firestore";
import HeaderShop from "../../elements/HeaderShop";
import ListItem from "../../elements/ListItem";
import { useFirestore } from "../../firebase/FirestoreContext";
import "../../styles/global.css";
import Cookies from "js-cookie";
import { UserContext } from "../../context/UserContext";

const ShopPage = ({ isDevMode }) => {
  const db = useFirestore();

  const [loading, setLoading] = useState(true);
  const [recentSearches, setRecentSearches] = useState([]);
  const [contentType, setContentType] = useState("products");
  const [searchResults, setSearchResults] = useState([]);
  //
  const [products, setProducts] = useState([]);
  const [fullProducts, setFullProducts] = useState([]); // Full product list from Firestore
  const [filteredProducts, setFilteredProducts] = useState([]); // Filtered product list
  const [searchTerm, setSearchTerm] = useState("");

  const { user, loading: userLoading } = useContext(UserContext);
  const userId = user ? user.uid : null;

  useEffect(() => {
    const collectionPath = isDevMode ? "_DEVELOPMENT/stageapi/" : "";

    const unsubscribe = onSnapshot(collection(db, `${collectionPath}Listings/ListingsList/DataBase`), (snapshot) => {
      const listings = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })).filter((listing) => listing.listing_price > 0);

      listings.sort((a, b) => {
        //
        if (a.listing_stock_amount === 0 && b.listing_stock_amount !== 0) {
          return 1;
        }
        if (a.listing_stock_amount !== 0 && b.listing_stock_amount === 0) {
          return -1;
        }
        return 0;
      });

      setProducts(listings);
      setFullProducts(listings);
      setLoading(false);
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, [db, isDevMode]);

  const applyFilters = (filters = {}, searchTerm = "") => {
    setLoading(true);

    // Filter by search term, category, type, and stock
    let filteredListings = fullProducts.filter((product) => {
      const matchesNameOrBrand =
        product.product_name.toLowerCase().includes(searchTerm.toLowerCase()) || product?.brand_name?.toLowerCase().includes(searchTerm.toLowerCase());

      const matchesCategory = !filters.categories || filters.categories.length === 0 || filters.categories.includes(product.product_category);

      const matchesType = !filters.types || filters.types.length === 0 || filters.types.includes(product.product_type);

      const matchesInStock = !filters.inStockOnly || product.listing_stock_amount > 0 || product.listing_stock_amount === -1;

      return matchesNameOrBrand && matchesCategory && matchesType && matchesInStock;
    });

    // Filter by "on sale" items if the sale filter is active
    if (filters.isOnSale) {
      filteredListings = filteredListings.filter((product) => {
        const now = new Date();

        const isSooperDealActive =
          product.sooperdeal_start_date &&
          product.sooperdeal_expiration_date &&
          new Date(product.sooperdeal_start_date) <= now &&
          new Date(product.sooperdeal_expiration_date) >= now;

        const isSaleActive =
          product.listing_sale_startDate &&
          product.listing_sale_endDate &&
          new Date(product.listing_sale_startDate) <= now &&
          new Date(product.listing_sale_endDate) >= now;

        return isSooperDealActive || isSaleActive; // Only return products on sale
      });
    }

    // Now calculate final price for each product
    filteredListings = filteredListings.map((product) => {
      const now = new Date();

      const isSooperDealActive =
        product.sooperdeal_start_date &&
        product.sooperdeal_expiration_date &&
        new Date(product.sooperdeal_start_date) <= now &&
        new Date(product.sooperdeal_expiration_date) >= now;

      const isSaleActive =
        product.listing_sale_startDate &&
        product.listing_sale_endDate &&
        new Date(product.listing_sale_startDate) <= now &&
        new Date(product.listing_sale_endDate) >= now;

      // Calculate final price based on SooperDeal, Sale, or Regular price
      const finalPrice = isSooperDealActive ? product.sooperdeal_price : isSaleActive ? product.listing_sale_price : product.listing_price;

      return {
        ...product,
        finalPrice, // Add the computed final price to each product
      };
    });

    // Sort the filtered items by final price if required
    if (filters.priceOrder === "low-to-high") {
      filteredListings.sort((a, b) => a.finalPrice - b.finalPrice); // Sort by final price ascending
    } else if (filters.priceOrder === "high-to-low") {
      filteredListings.sort((a, b) => b.finalPrice - a.finalPrice); // Sort by final price descending
    }

    // Update the filtered products list
    setProducts(filteredListings);
    setLoading(false);
  };

  const clearFilters = () => {
    setProducts(fullProducts); // Reset products to full list
    setSearchResults([]); // Clear any search results
    setContentType("products");
  };

  const fetchRecentSearches = async () => {
    if (userId) {
      const searchHistoryRef = collection(db, `Users/UsersList/DataBase/${userId}/SearchHistory`);
      const searchHistorySnapshot = await getDocs(searchHistoryRef);
      // list with doc id and data
      const searches = searchHistorySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setRecentSearches(searches);
    }
  };

  const deleteFromHistory = async (searchID) => {
    if (userId) {
      try {
        // Create a document reference for the specific search entry
        const searchHistoryRef = doc(db, `Users/UsersList/DataBase/${userId}/SearchHistory/${searchID}`);

        // Delete the document from Firestore
        await deleteDoc(searchHistoryRef);
        // fetch new recent searches
        fetchRecentSearches();
      } catch (error) {
        console.error("Error deleting search history item:", error);
      }
    }
  };

  // useEffects and call fetchRecentSearches when contentType is recentSearches
  useEffect(() => {
    if (contentType === "recentSearches") {
      fetchRecentSearches();
    }
  }, [contentType]);

  const toggleContent = (type) => {
    setContentType(type); // This updates the contentType

    if (type === "recentSearches") {
      console.log("Fetching recent searches"); // Add a log here to verify
      fetchRecentSearches(); // Call fetchRecentSearches when contentType is recentSearches
    }
  };

  const handleSearch = async (searchTerm) => {
    setLoading(true);
    const collectionPath = isDevMode ? "_DEVELOPMENT/stageapi/" : "";
    const listingsRef = collection(db, `${collectionPath}Listings/ListingsList/DataBase`);
    const searchSnapshot = await getDocs(listingsRef);

    const searchResults = searchSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })).filter((listing) => listing.listing_stock_amount !== 0);
    // sort by date

    setSearchResults(searchResults);
    setContentType("searchResults");
    setLoading(false);
  };

  // Show loading screen until user and age gate checks are complete
  if (userLoading || loading) {
    return <div className="loading">Loading...</div>;
  }

  //
  const handleSearchTerm = (term) => {
    setSearchTerm(term); // Update searchTerm state with the value from HeaderShop
    applyFilters({}, term); // Optionally, apply filters based on the search term
  };

  const handleSearchTermClick = (term) => {
    setSearchTerm(term); // Set the clicked search term to state
    applyFilters({}, term);
    setContentType("products");
  };

  return (
    <div className="shop-page">
      {Cookies.get("ageGate") === "true" && (
        <>
          <HeaderShop
            handleSearchTerm={handleSearchTerm}
            handleSearchTermClick={handleSearchTermClick}
            fetchRecentSearches={fetchRecentSearches}
            toggleContent={toggleContent}
            handleSearch={handleSearch}
            applyFilters={applyFilters}
            clearFilters={clearFilters}
            setContentType={setContentType} // Pass setContentType to HeaderShop
          />
          {contentType === "recentSearches" && recentSearches.length > 0 && (
            <div style={styles.recentSearchesContainer}>
              <div>
                <p
                  style={{ paddingBottom: 20, paddingTop: 10, cursor: "pointer" }}
                  // switch to products
                  onClick={() => setContentType("products")}
                >
                  <u>Close recent searches</u>
                </p>
                <h4 style={{ marginBottom: "" }}>Recent Searches</h4>
              </div>
              <ul>
                {recentSearches.map((search, index) => (
                  <li key={index} style={{ display: "flex", justifyContent: "space-between", padding: "8px 0", cursor: "pointer" }}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} onClick={() => handleSearchTermClick(search.searchTerm)}>
                      {" "}
                      {search?.searchTerm}
                    </div>
                    <div onClick={() => deleteFromHistory(search.id)} style={{ fontSize: 20, cursor: "pointer" }}>
                      &#215;
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {contentType === "searchResults" && products.length > 0 && (
            <div style={styles.productGrid}>
              {products.map((product, index) => (
                <ListItem key={index} product={product} index={index} dataMultiple={products} />
              ))}
            </div>
          )}
          {contentType === "products" && products.length > 0 && (
            <div style={styles.productGrid}>
              {products.map((product, index) => (
                <ListItem key={index} product={product} index={index} dataMultiple={products} />
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

const styles = {
  productGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
    justifyContent: "start",
    boxSizing: "border-box",
  },
  recentSearchesContainer: {
    marginBottom: "20px",
    padding: "10px",
    borderRadius: "5px",
  },
};

export default ShopPage;
