// src/functions/handleDeleteItem.js

import { doc, deleteDoc, getDoc, updateDoc, increment } from "firebase/firestore";

/**
 * Handles the deletion of a cart item.
 *
 * @param {Object} firestore - The Firestore instance.
 * @param {String} cartID - The cart ID.
 * @param {String} cartItemId - The cart item ID.
 * @param {Number} itemQuantity - The quantity of the item to be deleted.
 * @param {Function} updateCartItems - Function to update cart items in state.
 */
const handleDeleteItem = async (firestore, cartID, cartItemId, itemQuantity, updateCartItems) => {
  try {
    const itemRef = doc(firestore, `Carts/CartsList/DataBase/${cartID}/Items/${cartItemId}`);
    const itemDoc = await getDoc(itemRef);

    if (!itemDoc.exists()) {
      console.error("Item does not exist!");
      return;
    }

    const itemData = itemDoc.data();

    // Fetch the latest stock amount from Firestore
    const productRef = doc(firestore, `Listings/ListingsList/DataBase/${itemData.seller_product_id}`);
    const productDoc = await getDoc(productRef);

    if (!productDoc.exists()) {
      console.error("Product does not exist!");
      return;
    }

    const productData = productDoc.data();

    // Update listing stock amount
    if (productData.listing_stock_amount !== -1) {
      await updateDoc(productRef, {
        listing_stock_amount: increment(itemQuantity),
      });
    }

    await deleteDoc(itemRef);
    updateCartItems((prevItems) => prevItems.filter((item) => item.id !== cartItemId));
  } catch (error) {
    console.error("Error in handleDeleteItem:", error);
  }
};

export default handleDeleteItem;
