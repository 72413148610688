// src/elements/HeaderShop.js

import React, { useEffect, useState, useCallback } from "react";
import "../styles/global.css";
import { useNavigate } from "react-router-dom";
import { MenuDrawer } from "../screens/_menuDrawer";
import SearchFilters from "./SearchFilters";
import { useSearch } from "../context/SearchContext"; // Import context
import { getAuth } from "firebase/auth"; // Import Firebase Auth
import countCartItems from "../functions/countCartItems"; // Import countCartItems function
import { useFirestore } from "../firebase/FirestoreContext"; // Import Firestore context
// mui x font

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}

const HeaderShop = ({ handleSearchTerm, applyFilters, clearFilters, setContentType }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [localSearchTerm, setLocalSearchTerm] = useState("");
  const [isSearchEnabled, setSearchEnabled] = useState(false);
  const [cartCount, setCartCount] = useState(0); // State for cart item count
  const [filters, setFilters] = useState({
    categories: [],
    types: [],
    priceOrder: "",
    inStockOnly: false,
  });
  const [recentSearchTerms, setRecentSearchTerms] = useState([]);

  const navigate = useNavigate();
  const auth = getAuth();
  const user = auth.currentUser;
  const firestore = useFirestore(); // Get Firestore instance

  const searchContext = useSearch(); // Use search context
  const { setSearchTerm, searchProducts, storeSearchTerm, getHistoryTerms, toggleRecentSearches, showRecentSearches } = searchContext || {}; // Destructure with fallback

  useEffect(() => {
    window.feather.replace();
  }, [isMenuOpen]);

  useEffect(() => {
    setSearchEnabled(localSearchTerm.length > 3);
  }, [localSearchTerm]);

  useEffect(() => {
    let unsubscribe; // Variable to hold the unsubscribe function

    if (user) {
      // Subscribe to cart item count updates if user is logged in
      unsubscribe = countCartItems(firestore, user.uid, setCartCount);
    } else {
      setCartCount(0); // Set count to 0 if no user is logged in
    }

    // Cleanup listener when the component is unmounted
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [firestore, user]);

  const handleApplyFilters = (newFilters) => {
    setFilters(newFilters);
    applyFilters(newFilters, localSearchTerm); // Apply filters with the search term
  };

  //
  useEffect(() => {
    const debouncedSearch = debounce(() => {
      if (localSearchTerm.length) {
        applyFilters(filters, localSearchTerm); // Apply filters on search term
        searchProducts(localSearchTerm); // Search products
        toggleRecentSearches(false); // Hide recent searches
      }
    }, 300); // Debounce to avoid too many searches

    debouncedSearch(); // Trigger debounced search

    return () => {
      clearTimeout(debouncedSearch); // Clean up the timeout
    };
  }, [localSearchTerm, filters, applyFilters, searchProducts, toggleRecentSearches]); // Include dependencies to avoid re-renders

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const handleSearchInputChange = (e) => {
    const value = e.target.value;
    setLocalSearchTerm(value); // Update input state
    setSearchTerm(value); // Update search term in context
    handleSearchTerm(value);

    if (value.length === 0) {
      setContentType("recentSearches");
    } else {
      setContentType("products");
    }
  };
  const handleSearchClick = async () => {
    if (isSearchEnabled) {
      await storeSearchTerm(localSearchTerm); // Store the search term
      applyFilters(filters, localSearchTerm); // Apply filters with the search term
      setContentType("searchResults"); // Switch to search results content
      searchProducts(localSearchTerm); // Perform search
      toggleRecentSearches(false); // Hide recent searches
    }
  };

  const handleFocus = async () => {
    const terms = await getHistoryTerms(); // Fetch recent searches
    setRecentSearchTerms(terms); // Store them in state
    setContentType("recentSearches"); // Set content type to "recentSearches"
    toggleRecentSearches(true); // Show recent searches
  };

  return (
    <>
      <header className="header-shop" style={styles.headerShop}>
        <div style={styles.header.container}>
          <div style={styles.menu.container} onClick={toggleMenu}>
            <i data-feather={isMenuOpen ? "x" : "menu"} style={{ width: "16px", height: "16px" }} />
          </div>
          <div style={styles.logo.container} onClick={() => navigate("/")}>
            {/* 
            <span className="logo" style={styles.logo.text}>
              SOOPERSTOCK
            </span>
            */}
            <img src="/images/sooperstock_logo.svg" alt="Sooperstock" style={{ height: "35px" }} />
          </div>
          <div style={styles.cart.container} onClick={() => navigate("/cart")}>
            <div style={{ width: "16px", height: "16px", position: "relative" }}>
              <i data-feather={"shopping-bag"} style={{ width: "16px", height: "16px" }} />
              <div style={styles.cartCount}>{cartCount}</div> {/* Display current number of items in cart */}
            </div>
          </div>
        </div>
        {/** SEARCH AREA */}
        <div style={styles.search.container}>
          <div style={styles.search.inputFieldContainer}>
            <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
              <input
                type="text"
                placeholder="Search Products"
                style={{ ...styles.search.inputField }}
                value={localSearchTerm}
                onChange={handleSearchInputChange}
                onFocus={handleFocus}
                onBlur={() => toggleRecentSearches(false)} // Hide recent searches on blur
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearchClick(); // Call the search function when Enter is pressed
                  }
                }}
              />

              {isSearchEnabled && (
                <div
                  style={{
                    flex: 1,
                    padding: "0px 20px",
                    color: "black",
                    alignItems: "center",
                    display: "flex",
                    zIndex: 1000,
                    fontWeight: "300",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    clearFilters();
                    setLocalSearchTerm("");
                    setSearchTerm("");
                  }}
                >
                  &#10005;
                </div>
              )}
            </div>
            <div style={styles.searchButtonContainer}>
              <button
                style={{ ...(isSearchEnabled ? styles.searchButton.enabled : styles.searchButton.disabled), ...styles.searchButton.button }}
                disabled={!isSearchEnabled}
              >
                <div onClick={handleSearchClick} disabled={!isSearchEnabled} style={{ padding: "20px 10px" }}>
                  SEARCH
                </div>
              </button>
              <button onClick={clearFilters}>Clear Filters</button>
            </div>
          </div>
        </div>
        <SearchFilters onApplyFilters={handleApplyFilters} />
      </header>

      <MenuDrawer isOpen={isMenuOpen} toggleMenu={toggleMenu} />
    </>
  );
};

// Styles for HeaderShop component (unchanged)
const styles = {
  headerShop: {
    backgroundColor: "#fff",
  },
  header: {
    container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "20px 0",
      borderBottom: ".2px solid #808080",
    },
  },
  menu: {
    container: {
      padding: "0 20px",
      cursor: "pointer",
    },
  },
  cart: {
    container: {
      padding: "0 20px",
      cursor: "pointer",
    },
  },
  cartCount: {
    position: "absolute",
    top: "-9px",
    right: "-13px",
    backgroundColor: "black",
    borderRadius: "50%",
    color: "white",
    fontSize: "10px",
    height: "18px",
    width: "18px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    fontWeight: 600,
  },
  logo: {
    container: {
      cursor: "pointer",
    },
    text: {
      fontSize: "18px",
    },
  },
  search: {
    container: {
      flexDirection: "row",
      display: "flex",
    },
    inputFieldContainer: {
      display: "flex",
      width: "100%",
      height: "45px",
      flex: 1,
    },
    inputField: {
      width: "100%",
      border: "none",
      height: "100%",
      outline: "none",
      fontSize: "14px",
    },
  },
  searchButtonContainer: {},
  searchButton: {
    button: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    enabled: {
      backgroundColor: "black",
      color: "white",
      border: "none",
      height: "100%",
      cursor: "pointer",
      width: "100%",
      padding: "0 10px 0 15px",
    },
    disabled: {
      backgroundColor: "gray",
      color: "white",
      border: "none",
      height: "100%",
      cursor: "not-allowed",
      width: "100%",
      padding: "0 10px 0 15px",
    },
  },
};

export default HeaderShop;
