import React, { useEffect, useState } from "react";
import AppRouter from "./navigation/AppRouter";
import { UserContextProvider } from "./context/UserContext";
import { SearchProvider } from "./context/SearchContext";
import { FirestoreContextProvider } from "./firebase/FirestoreContext";

import Cookies from "js-cookie";
import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, getDoc, setDoc, updateDoc, collection, addDoc, onSnapshot } from "firebase/firestore";

import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import styled from "@mui/material/styles/styled";

import "./styles/global.css";
import { BorderRight } from "@material-ui/icons";
import e from "cors";

const SooperSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "white",
    "&:hover": {
      backgroundColor: "rgba(255,255,255,.2)",
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "silver",
  },
}));

// Constant for cookie preferences
const defaultCookieSettings = {
  ads: true,
  tech: true,
  functional: true,
  marketing: true,
  statistical: true,
};

const App = () => {
  const [user, setUser] = useState(null);
  const [userSettings, setUserSettings] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showCookieBanner, setShowCookieBanner] = useState(false);
  const [cookieSettings, setCookieSettings] = useState(defaultCookieSettings);
  const [displayCookieDetails, setDisplayCookieDetails] = useState(false);

  const [showNewsletterModal, setShowNewsletterModal] = useState(false); // Newsletter modal state
  const [newsletterAccepted, setNewsletterAccepted] = useState(false); // Check if already accepted
  const [emailInput, setEmailInput] = useState(""); // Email input state

  // Password protection
  const [passwordGate, setPasswordGate] = useState(false);
  const [passwordInput, setPasswordInput] = useState("");
  const [showAgeGate, setShowAgeGate] = useState(false);
  const [showAgeRestrictionMessage, setShowAgeRestrictionMessage] = useState(false);

  const correctPassword = "3322";

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (passwordInput === correctPassword) {
      setPasswordGate(true);
      // set password cookie
      Cookies.set("passwordGate", true, { expires: 1 });
    } else {
      alert("Incorrect password!");
    }
  };

  useEffect(() => {
    const newsletterConsent = Cookies.get("newsletterResponded");

    // password gate
    const passwordGateCookie = Cookies.get("passwordGate");
    if (!passwordGateCookie) {
      setPasswordGate(false);
    } else {
      setPasswordGate(true);
    }

    if (!newsletterConsent) {
      // Delay showing the modal by a minute (optional)
      setTimeout(() => {
        setShowNewsletterModal(true);
      }, 60000); // Adjust the delay as needed
    }
  }, []);

  useEffect(() => {
    const existingUserUID = Cookies.get("anonymousUID");

    const auth = getAuth();
    const firestore = getFirestore();

    const unsubscribeAuth = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser && currentUser.isAnonymous && existingUserUID === currentUser.uid) {
        // If current user is already anonymous and matches the UID stored in cookies
        setUser(currentUser);
        await setupUserFirestoreListener(currentUser.uid, firestore);
        checkNewsletterConsent(); // Check newsletter consent
      } else if (!existingUserUID) {
        // No valid user found, sign in anonymously
        try {
          const anonUser = await signInAnonymously(auth);
          setUser(anonUser.user);
          Cookies.set("anonymousUID", anonUser.user.uid, { expires: 365 }); // Store for 1 year
          await setupUserFirestoreListener(anonUser.user.uid, firestore);
          checkNewsletterConsent(); // Check newsletter consent
        } catch (error) {
          console.error("Error during anonymous login:", error);
        }
      }

      // Now get the user data from Firestore
      if (currentUser) {
        const userDocRef = doc(firestore, `Users/UsersList/DataBase`, currentUser.uid);
        try {
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const userData = await userDocSnap.data();
            await setUserSettings(userData); // Save the user data to state
            // set user cookie settings
            await setCookieSettings({
              tech: true,
              functional: userData.cookie?.functional || defaultCookieSettings.functional,
              marketing: userData.cookie?.marketing || defaultCookieSettings.marketing,
              statistical: userData.cookie?.statistical || defaultCookieSettings.statistical,
            });
          } else {
            console.log("No such user document!");
          }
        } catch (error) {
          console.error("Error fetching user document:", error);
        }
      }

      setIsLoading(false);
    });

    return () => unsubscribeAuth();
  }, []);

  const setupUserFirestoreListener = async (uid, firestore) => {
    const userDocRef = doc(firestore, "Users/UsersList/DataBase", uid);
    onSnapshot(userDocRef, (doc) => {
      if (doc.exists()) {
        setUserSettings(doc.data());
      }
    });
  };

  //
  // Age gate logic

  useEffect(() => {
    if (Cookies.get("ageGate") !== "true") {
      setShowAgeGate(true);
    }
  }, []);

  const handleAcceptAgeGate = async () => {
    if (user?.uid) {
      const userRef = doc(getFirestore(), "Users/UsersList/DataBase", user.uid);
      await setDoc(userRef, { ageGateAccepted: true }, { merge: true });
    }
    Cookies.set("ageGate", "true", { expires: 365 });
    setShowAgeGate(false);
  };

  const handleDeclineAgeGate = () => {
    setShowAgeRestrictionMessage(true);
    setShowAgeGate(false);
  };
  const renderAgeGateModal = () => {
    if (!showAgeGate) return null;

    return (
      <div style={styles.modal}>
        <div style={styles.modalContent}>
          {!showAgeRestrictionMessage ? (
            <>
              <h2>Are you 21 or older?</h2>
              <div style={styles.modalButtons}>
                <Button onClick={handleAcceptAgeGate} style={{ ...styles.modalButton, background: "#90ee90", color: "black" }}>
                  Yes
                </Button>
                <Button onClick={handleDeclineAgeGate} style={{ ...styles.modalButton, background: "#ffcccb", color: "black" }}>
                  No
                </Button>
              </div>
            </>
          ) : (
            <div style={{ padding: "20px" }}>
              <span>Sorry, you must be 21 or older to access this site.</span>
            </div>
          )}
        </div>
      </div>
    );
  };

  //
  // Newsletter modal logic
  useEffect(() => {
    const newsletterResponded = Cookies.get("newsletterResponded");
    if (newsletterResponded) {
      setShowNewsletterModal(false);
    }
  });

  const checkNewsletterConsent = () => {
    if (Cookies.get("newsletterResponded") !== "true") {
      setTimeout(() => {
        setShowNewsletterModal(true);
      }, 60000);
    }
  };

  const handleAcceptNewsletter = async () => {
    // email validation
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailRegex.test(emailInput) || emailInput.length < 5 || !emailInput.includes("@") || !emailInput) {
      alert("Please enter a valid email address");
      return;
    }

    const firestore = getFirestore();

    try {
      // Add email to Firestore collection
      await addDoc(collection(firestore, "Newsletter/Subscribers/DataBase"), {
        email: emailInput,
        subscribedAt: new Date(),
      });

      // Set the cookie to prevent future popups
      Cookies.set("newsletterResponsed", true, { expires: 365 });
      setShowNewsletterModal(false);

      // Send confirmation email by inserting into Messages/Mail/DataBase
      await addDoc(collection(firestore, "Messages/Mail/DataBase"), {
        to: [emailInput],
        message: {
          subject: "Welcome to the Sooperstock Newsletter!",
          html: `
            <div style="font-family: Arial, sans-serif; color: #333; text-align: center; padding: 20px; max-width: 600px; margin: auto; border: 1px solid #ddd; border-radius: 10px;">
              <div style="padding-bottom: 20px;">
                <img src="https://firebasestorage.googleapis.com/v0/b/sooperstock-app.appspot.com/o/Media%2Fsooperstock_logo.png?alt=media&token=42cec348-56a0-410b-bd76-aea8248913f1" alt="Sooperstock Logo" style="width: 150px; height: auto;">
              </div>
      
              <h1 style="font-size: 24px; color: #000;">Welcome to the Sooperstock Newsletter!</h1>
              
              <p style="font-size: 16px; color: #666;">
                You signed up for our newsletter. Welcome to the club!
              </p>
      
              <p style="font-size: 16px; color: #666;">
                You’ll now get the hottest deals and newest product drops straight to your inbox.
              </p>
      
              <div style="margin-top: 30px;">
                <p style="font-size: 16px; font-weight: bold; color: #000;">
                  Thank you,
                </p>
                <p style="font-size: 16px; color: #666;">
                  The Sooperstock Team
                </p>
              </div>
      
              <div style="margin-top: 40px; font-size: 12px; color: #aaa; display: none">
                <p style="font-size: 12px; color: #aaa;">
                  You're receiving this email because you signed up for the Sooperstock Newsletter. If you wish to unsubscribe, click here.
                </p>
              </div>
            </div>
          `,
          text: `
            Welcome to the Sooperstock Newsletter!
      
            You signed up for our newsletter. Welcome to the club!
      
            You’ll now get the hottest deals and newest product drops straight to your inbox.
      
            Thank you,
            The Sooperstock Team
          `,
        },
      });

      alert("You're signed up!");
    } catch (error) {
      console.error("Error signing up for newsletter:", error);
      alert("Failed to sign up for the newsletter.");
    }
  };

  const handleDeclineNewsletter = () => {
    Cookies.set("newsletterResponded", true, { expires: 1 });
    setShowNewsletterModal(false);
  };

  const renderNewsletterModal = () => {
    if (!showNewsletterModal || newsletterAccepted) return null;

    return (
      <div style={{ ...styles.modal, flexDirection: "column", padding: "20px" }}>
        <div
          style={{
            ...styles.modalContent,
            width: "100%",
            padding: "5px",
            justifyContent: "flex-end",
            display: "flex",
          }}
        >
          <div
            onClick={handleDeclineNewsletter}
            style={{
              display: "flex",
              padding: "10px 20px",
              fontSize: "1.7rem",
              cursor: "pointer",
            }}
          >
            &times;
          </div>
        </div>
        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <div style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img src="/images/sooperstock_logo.svg" alt="Sooperstock" style={{ height: "55px" }} />
          </div>
          <div style={{ display: "flex", flex: 1, alignItems: "flex-start", textAlign: "center" }}>
            <div>
              <h2 style={{ paddingBottom: "10px" }}>Sign up for our newsletter</h2>
              <p>GET FIRST LOOK AT EXCLUSIVE DEALS AND NEW PRODUCT DROPS!</p>
              <input
                type="email"
                placeholder="Enter your email"
                value={emailInput}
                onChange={(e) => setEmailInput(e.target.value)}
                style={{ ...styles.modalInput, width: "100%" }}
              />
              <div style={styles.modalButtons}>
                <Button onClick={handleAcceptNewsletter} style={{ ...styles.modalButton, width: "100%" }}>
                  Continue
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  //
  // Handle cookie banner logic
  useEffect(() => {
    const cookieResponded = Cookies.get("cookieResponded");
    if (cookieResponded) {
      setShowCookieBanner(false);
    }
  });

  const handleCookieChange = (field, value) => {
    setCookieSettings((prevSettings) => ({
      ...prevSettings,
      [field]: value,
    }));
  };

  const handleAcceptCookies = async () => {
    Cookies.set("cookieResponded", true, { expires: 365 });
    setShowCookieBanner(false);

    if (user?.uid) {
      const firestore = getFirestore();
      const userDocRef = doc(firestore, "Users/UsersList/DataBase", user.uid);

      await setDoc(
        userDocRef,
        {
          cookie: {
            tech: cookieSettings.tech,
            functional: cookieSettings.functional,
            marketing: cookieSettings.marketing,
            statistical: cookieSettings.statistical,
          },
        },
        { merge: true }
      );
    }
  };

  const handleDeclineCookies = async () => {
    // Handle cookie decline logic, could also store this preference
    Cookies.set("cookieResponded", true, { expires: 365 });
    setShowCookieBanner(false);
  };

  const renderCookiesBanner = () => {
    if (!showCookieBanner) return null;

    return (
      <div style={styles.cookie.container}>
        <div style={{ ...styles.cookie.content, justifyContent: "center", alignItems: "center" }}>
          {displayCookieDetails == false && (
            <div style={{ width: "100%", flex: 1, display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <div>
                By clicking 'Accept All' you consent that we may collect information about you for various purposes, including: Functionality, Analytics and
                Marketing
              </div>
              <div style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
                <div onClick={() => setDisplayCookieDetails(true)} style={{ cursor: "pointer", color: "blue" }}>
                  Customize settings
                </div>
              </div>
            </div>
          )}
          {displayCookieDetails == true && (
            <div style={{ width: "100%", flex: 1, display: "flex", flexDirection: "column" }}>
              <div style={{ padding: 20, flex: 1, width: "100%" }}>
                <div style={{ justifyContent: "space-between", width: "100%", display: "flex" }}>
                  <h2 style={{ fontSize: "1rem", fontWeight: "bold", marginBottom: 16, width: "100%" }}>YOU CONTROL YOUR DATA</h2>
                  <div onClick={() => setDisplayCookieDetails(false)} style={{ cursor: "pointer", color: "black", float: "right" }}>
                    x
                  </div>
                </div>
                <p style={{ fontSize: ".9rem", marginBottom: 12 }}>
                  We and our partners use technologies, including cookies, to collect information about you for various purposes, including:
                </p>
                <ol style={{ fontSize: ".9rem", paddingLeft: 20, marginBottom: 16 }}>
                  <li>Functionality</li>
                  <li>Analytics</li>
                  <li>Marketing</li>
                </ol>
                <p style={{ fontSize: ".9rem", marginBottom: 20 }}>
                  By clicking 'Accept All' you consent to all these purposes. You can also choose to indicate what purposes you will consent to using the custom
                  selections and then click 'Save my choices'.
                </p>
              </div>

              <div style={styles.cookie.options}>
                <div style={styles.cookie.options.container}>
                  <div style={styles.cookie.options.content}>
                    <span style={{ fontSize: ".9rem" }}>Necessary</span>
                    <SooperSwitch disabled color="default" checked={cookieSettings.tech} onChange={(e) => handleCookieChange("tech", e.target.checked)} />
                  </div>
                  <div style={styles.cookie.options.content}>
                    <span style={{ fontSize: ".9rem" }}>Functional</span>
                    <SooperSwitch color="default" checked={cookieSettings.functional} onChange={(e) => handleCookieChange("functional", e.target.checked)} />
                  </div>
                </div>
                <div style={styles.cookie.options.container}>
                  <div style={styles.cookie.options.content}>
                    <span style={{ fontSize: ".9rem" }}>Marketing</span>
                    <SooperSwitch color="default" checked={cookieSettings.marketing} onChange={(e) => handleCookieChange("marketing", e.target.checked)} />
                  </div>
                  <div style={styles.cookie.options.content}>
                    <span style={{ fontSize: ".9rem" }}>Analytics</span>
                    <SooperSwitch color="default" checked={cookieSettings.statistical} onChange={(e) => handleCookieChange("statistical", e.target.checked)} />
                  </div>
                </div>
              </div>
            </div>
          )}

          <div style={styles.cookie.buttons}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                alert(JSON.stringify(cookieSettings));
                setCookieSettings({
                  tech: cookieSettings.tech,
                  functional: cookieSettings.functional,
                  marketing: cookieSettings.marketing,
                  statistical: cookieSettings.statistical,
                });
                handleAcceptCookies();
              }}
              style={{ marginRight: 16, width: "100%", background: "black", color: "white", marginBottom: 10 }}
            >
              {displayCookieDetails == true ? "ACCEPT SELECTED" : "ACCEPT ALL"}
            </Button>
            <Button variant="outlined" color="" style={{ marginRight: 16, width: "100%", background: "black", color: "white" }} onClick={handleDeclineCookies}>
              DECLINE ALL
            </Button>
          </div>
        </div>
      </div>
    );
  };

  if (passwordGate != true) {
    return (
      <div style={{ height: "100vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <form onSubmit={handlePasswordSubmit}>
          <input
            type="password"
            placeholder="Enter password"
            value={passwordInput}
            onChange={(e) => setPasswordInput(e.target.value)}
            style={{ height: 50, marginRight: 10 }}
          />
          <Button type="submit" variant="contained" color="" style={{ background: "#ff0000", color: "white", height: 50, marginTop: -3 }}>
            Submit
          </Button>
        </form>
      </div>
    );
  }

  return (
    <FirestoreContextProvider>
      <UserContextProvider>
        <SearchProvider>
          <AppRouter />
          {renderCookiesBanner()}
          {renderNewsletterModal()}
          {renderAgeGateModal()}
        </SearchProvider>
      </UserContextProvider>
    </FirestoreContextProvider>
  );
};

const styles = {
  modal: {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100vw",
    height: "100vh",
    backgroundColor: "white",
    zIndex: 1001,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContent: {
    textAlign: "center",
  },
  modalButtons: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "space-around",
  },
  modalButton: {
    backgroundColor: "#000",
    color: "#fff",
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "pointer",
  },
  modalInput: {
    padding: "10px",
    width: "80%",
    marginTop: "20px",
    border: "1px solid #ccc",
    borderRadius: "5px",
  },
  cookie: {
    container: {
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      color: "white",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
      zIndex: 1000,
      height: "100vh",
      padding: "10px",
      alignContent: "flex-end",
    },
    content: {
      backgroundColor: "white",
      borderRadius: "10px",
      width: "100%",
      color: "black",
      padding: "20px",
      fontSize: "1rem",
      lineHeight: "1.5",
    },
    text: {
      maxWidth: "70%",
      fontSize: "0.9rem",
    },
    options: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "0 20px",
      container: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
      },
      content: {
        display: "flex",
        flex: 1,
        alignItems: "flex-start",
        textAlign: "left",
        flexDirection: "column",
        width: "100%",
      },
    },
    buttons: {
      display: "flex",
      justifyContent: "flex-end",
      flexDirection: "column",
      marginTop: 20,
    },
  },
  //
  ageGateOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
  },
  ageGatePopup: {
    backgroundColor: "#fff",
    padding: "30px",
    borderRadius: "5px",
    textAlign: "center",
  },
  ageRestrictionMessage: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
    textAlign: "center",
  },
  agebutton: {
    backgroundColor: "#f9f9f9",
    padding: "15px 30px",
    margin: "10px",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    border: ".5px solid #ddd",
    fontWeight: "bold",
  },
};

export default App;
